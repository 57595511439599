import myaxios from './Outlet'

var axios = require('axios')

const urlOrder = process.env.VUE_APP_version + '/product-order-be/orders'
const urlOrderHistory = process.env.VUE_APP_version + '/product-order-be'

axios.create = function (data, success, callBackError) {
  this.post(urlOrder, data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.getOrder = function (uuid, success, callBackError) {
  axios.options.flag = false
  axios.options.notify = false
  this.get(urlOrder + '/' + uuid)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && (error.response.status === 400 || error.response.status == 404)) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.updateTrackingStatus = function (uuid, params, success, callBackError) {
  axios.options.flag = false
  axios.options.notify = false
  this.put(urlOrder + '/tracking/' + uuid, params)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && (error.response.status === 400 || error.response.status == 404)) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.tracking = function (data, success, callBackError) {
  axios.options.notify = false
  this.post(urlOrder + '/check-order', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 404) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.history = function (data, success, callBackError) {
  this.get(urlOrderHistory + '/list-orders-by-user', {params: data})
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 404) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
export default axios
