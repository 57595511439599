import main from '../main.js'
const moment = require('moment')
var axios = require('axios')
const url = 'api'
const prefixLocalisation = '/product-localisation/'
const prefixfe = process.env.VUE_APP_version + '/product-hut-fe/'
const prefixfev2 = '/product-hut-fe/'
const localizationURL = process.env.VUE_APP_version + '/product-loc-americas/'
const urlv2 = process.env.VUE_APP_host + '/' + process.env.VUE_APP_version2
/* axios.options = {flag: true}
/!**
 * Setting url for outlet
 * *!/
const myaxios = axios.create({
  baseURL: process.env.VUE_APP_host + '/' + process.env.VUE_APP_version,
  headers: {'Content-Type': 'application/json',
    'Client': process.env.VUE_APP_CLIENT_ID
  }
})
myaxios.interceptors.request.use(
  success => {
    if (myaxios.options.flag) {
      if (success.url != 'mappings') {

      }
      main.$bus.$emit('loading', 'block')
    }
    return success
  },
  error => {
    main.$bus.$emit('loading', 'none')
    console.log('Can not response API server. Please check it again!')
    return Promise.reject(error)
  }
)
myaxios.interceptors.response.use(
  success => {
    myaxios.options.flag = true
    main.$bus.$emit('loading', 'none')
    return success
  },
  error => {
    myaxios.options.flag = true
    main.$bus.$emit('loading', 'none')
    console.log('Can not response API server. Please check it again!')
    return Promise.reject(error)
  }
) */
/**
 * Get list outLets for take-away or a outlet for delivery
 * */
axios.checkLocation = function (data, success, callBackError) {
  this.get(prefixfe + 'localizations', { params: data })
    .then(response => {
      success(response)
    })
    .catch(error => {
      if (
        error.response &&
        error.response.status === 400 &&
        typeof callBackError !== 'undefined'
      ) {
        callBackError(error.response.data)
      }
    })
}
/**
 * Get list outLets by postal code
 * */
axios.getNextLevelData = function(data, success, callBackError) {
  axios.options.flag = false
  this.get(prefixLocalisation + 'search', { params: data })
    .then(response => {
      success(response)
    })
    .catch(error => {
      if (
        error.response &&
        error.response.status === 400 &&
        typeof callBackError !== 'undefined'
      ) {
        callBackError(error.response.data)
      }
    })
}

/**
 * Checking Outlet is available
 * */
axios.checkOutletAvailable = function (data, success, callBackError) {
  this.post(urlv2 + prefixfev2 + 'outlets/available', data).then(response => {
    success(response)
  }).catch(error => {
    if (error.response && error.response.status == 400 && typeof callBackError !== 'undefined') {
      callBackError(error.response.data)
    }
  })
}

/**
 * Checking PRB Outlet cross streets
 * */
axios.checkPRBOutletCrossStreets = function (data, success, callBackError) {
  const client = axios.defaults.headers.common['Client']
  const lang = axios.defaults.headers.common['Lang']
  // Avoid unnecessary preflight check
  delete axios.defaults.headers.common['Client']
  delete axios.defaults.headers.common['Lang']
  const {
    streetName,
    outletCode,
    streetNumber,
    neighborhoodName,
    cityName,
    stateName,
    latitude,
    postalCode,
    longitude

  } = data
  // Skip PRB cross streets check for test store lab1
  if (outletCode === 'lab1') {
    axios.defaults.headers.common['Client'] = client
    axios.defaults.headers.common['Lang'] = lang
    return success({ status: 200 });
  }

  // Check PRB cross streets for other stores
  this.get(`${localizationURL}localization?market=mx` +
    `&street=${streetName ? encodeURIComponent(streetName) : 'Calle'}` +
    `&outletCode=${outletCode}` +
    `&streetNumber=${streetNumber}` +
    `&neighborhood=${encodeURIComponent(neighborhoodName)}` +
    `&city=${encodeURIComponent(cityName)}` +
    `&state=${encodeURIComponent(stateName)}` +
    `&postalCode=${postalCode}` +
    `&latitude=${latitude}&longitude=${longitude}` +
    `&_=` + moment().unix())
    .then(response => {
      axios.defaults.headers.common['Client'] = client
      axios.defaults.headers.common['Lang'] = lang
      success(response)
    }).catch(error => {
      axios.defaults.headers.common['Client'] = client
      axios.defaults.headers.common['Lang'] = lang
      if (typeof callBackError !== 'undefined') {
        callBackError(error)
      }
    })
}

/**
 * Localization logging to Kibana
 * */
axios.loggingLocalization = async (data) => {
  try {
    await axios.post(`${localizationURL}logging`, data)
  } catch (err) {
    console.error(err)
  }
}

/** https://api.phdvasia.com/v1/product-hut-fe/opening_hours_summary/5?order_type=C
 * Get outlet's opening hours
 * */
axios.getOutletOpeningHours = function (data, success, callBackError) {
  this.get(urlv2 + prefixfev2 + 'outlets/opening_hours/' + data.outletId, {params: data}).then(response => {
    success(response)
  }).catch(error => {
    if (error.response && error.response.status == 400 && typeof callBackError !== 'undefined') {
      callBackError(error.response.data)
    }
  })
}

/**
 * Get outlets by address code or zipcode or outletid
 * */
axios.getOutletByAddressCode = function (data, success, callBackError) {
  const client = axios.defaults.headers.common['Client']
  const lang = axios.defaults.headers.common['Lang']
  // Avoid unnecessary preflight check
  delete axios.defaults.headers.common['Client']
  delete axios.defaults.headers.common['Lang']
  this.get(localizationURL + 'cep/' + data.zip_code)
    .then(response => {
      axios.defaults.headers.common['Client'] = client
      axios.defaults.headers.common['Lang'] = lang
      success(response)
    })
    .catch(error => {
      axios.defaults.headers.common['Client'] = client
      axios.defaults.headers.common['Lang'] = lang
      if (
        error.response &&
        error.response.status === 400 &&
        typeof callBackError !== 'undefined'
      ) {
        callBackError(error.response.data)
      }
    })
}

/**
 * Get outlets by outlet code
 * */
axios.getOutLetByCode = function(data, success, callBackError) {
  this.get(prefixfe + 'outlets/detail/' + data.code, { params: data })
    .then(response => {
      success(response)
    })
    .catch(error => {
      if (
        error.response &&
        error.response.status === 404 &&
        typeof callBackError !== 'undefined'
      ) {
        callBackError(error.response.data)
      }
    })
}

axios.getAddressByAddressCode = function(data, success, callBackError) {
  this.get(prefixfe + 'address/', { params: data })
    .then(response => {
      success(response)
    })
    .catch(error => {
      if (
        error.response &&
        error.response.status === 404 &&
        typeof callBackError !== 'undefined'
      ) {
        callBackError(error.response.data)
      }
    })
}
export default axios
