var axios = require('axios')
const urlCustomer = process.env.VUE_APP_version + '/product-customer'
const urlConvertKana = process.env.VUE_APP_version + '/product-jp/nihongo/convert-kana'

axios.register = function (data, success, callBackError) {
  this.post(urlCustomer + '/customer_registration', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.getProfile = function (success, callBackError) {
  this.get(urlCustomer + '/customers-by-token')
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
      // if (typeof callBackError !== 'undefined') {
      //   callBackError(error.response.data)
      // }
      }
    })
}

axios.updateProfile = function (data, success, callBackError) {
  this.put(urlCustomer + '/customers', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.createLocation = function (data, success, callBackError) {
  this.post(urlCustomer + '/locations', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.editLocation = function (data, success, callBackError) {
  this.put(urlCustomer + '/locations', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.getLocation = function (data, success, callBackError) {
  this.get(urlCustomer + '/locations/' + data)
    .then(response => {
      success(response)
    }).catch(error => {
      console.log(error)
      if (error.response) { callBackError(error) }
    })
}

axios.deleteLocation = function (data, success, callBackError) {
  this.delete(urlCustomer + '/locations/' + data)
    .then(response => {
      success(response)
    }).catch(error => {
      console.log(error)
      if (error.response) { callBackError(error) }
    })
}
axios.registerConfirmation = function (data, success) {
  this.get(urlCustomer + '/confirm_email/' + data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response) { callBackError(error) }
    })
}

axios.checkExistEmail = function (data, success, callBackError) {
  this.post(urlCustomer + '/check_email', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.resetPassword = function (data, success, callBackError) {
  this.post(urlCustomer + '/reset_password', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.checkTokenResetPassowrd = function (data, success, callBackError) {
  this.post(urlCustomer + '/check_forgot_password_token', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.sendToFeedback = function (data, success, callBackError) {
  this.post(urlCustomer + '/contacts', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.convertKana = function (text, success, callBackError) {
  let api = urlConvertKana
  if (text) {
    api += '?name=' + text

    this.get(api)
      .then(response => {
        success(response)
      }).catch(error => {
        if (error.response && error.response.status === 400) {
        }
      })
  }
}

export default axios
