import { render, staticRenderFns } from "./EmergencyBanner.vue?vue&type=template&id=2cac58da&scoped=true&"
import script from "./EmergencyBanner.vue?vue&type=script&lang=js&"
export * from "./EmergencyBanner.vue?vue&type=script&lang=js&"
import style0 from "./EmergencyBanner.vue?vue&type=style&index=0&id=2cac58da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cac58da",
  null
  
)

export default component.exports