var { GOOGLE_API_KEY } = require('../constants').default
var axios = require('axios')

axios.queryGoogleGeocoding = function (query, success, callBackError) {
  if (query && query.length > 0) {
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode( { 'address': query, 'region': 'es' }, function(results, status) {
      if (status === 'OK') {
        success(results)
      } else {
        callBackError([])
      }
    })
  } else {
    callBackError([])
  }
}
