var axios = require('axios')
const urlGameInfo = process.env.VUE_APP_version + '/product-coupon-fe/game-info/'
const urlPlay = process.env.VUE_APP_version + '/product-coupon-fe/play-game/'
const urlHistory = process.env.VUE_APP_version + '/product-coupon-fe/history'

axios.options.flag = false

axios.getGameInfo = function (uuid, token, success, callBackError) {
  axios.options.flag = false
  var url = urlGameInfo + uuid

  const auth = {
    headers: {
      'x-token': token
    }
  }

  this.get(url, auth)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400 && typeof callBackError !== 'undefined') {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.playGame = function (uuid, token, success, callBackError) {
  axios.options.flag = false
  var url = urlPlay + uuid

  const auth = {
    headers: {
      'x-token': token
    }
  }

  this.post(url, {}, auth)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400 && typeof callBackError !== 'undefined') {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.getHistory = function (uuid, data, success, callBackError) {
  axios.options.flag = false
  this.get(urlMenu + '/' + uuid, {params: data}).then(response => {
    success(response)
  }).catch(error => {
    if (error.response && error.response.status === 400 && typeof callBackError !== 'undefined') {
      callBackError(error.response.data)
    }
  })
}

export default axios
