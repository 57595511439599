<template>
  <div class="ReactModalPortal order-datetime-modal" data-synth="asap-modal">
    <div class="ReactModal__Overlay ReactModal__Overlay--after-open" class-open="ReactModal__Overlay--after-open"
         style="position: fixed; top: 0px; left: 0px; right: 0px; bottom: 0px; background-color: rgba(255, 255, 255, 0.75);"
         aria-modal="true">
      <div
        class="ReactModal__Content overlay xs-full-height xs-full-width xs-mb-0 xs-flex xs-items-center ReactModal__Content--after-open"
        class-open="ReactModal__Content--after-open" tabindex="-1" aria-label="DelayedOrderModal">
        <div v-if="isClosedForTheDay()" class="" style="width: 100%; height: 100%;">
          <div class="xs-pt-60 xs-px-40 xs-text-white pb-20">
            <p class="xs-mb-20 text-center t24" v-if="message" v-html="message"></p>
            <p class="xs-mb-20 text-center t24">{{$t('store_closed_come_back_later')}} </p>
          </div>
          <div class=" pt-100 action-close-asap-popup">
            <button style="width: 45%;margin: auto;" type="button"
                    class="button button--primary xs-text-center close-asap-popup" @click="$emit('close')">
              {{$t('modal.close_asap_popup')}}
            </button>
          </div>
        </div>
        <div v-if="!isClosedForTheDay()" class="" style="width: 100%; height: 100%;">
          <div class="xs-pt-60 xs-px-40 xs-text-white pb-20">
            <p class="xs-mb-20 text-center t24" v-if="message" v-html="message"></p>
            <p class="xs-mb-20 text-center t24" v-html="$t('modal.select_good_time')"></p>
            <p class="xs-mb-20 text-center t24">{{$t('what_time_would_you_like_your_order')}} </p>
          </div>
          <button type="button"
                  class="icon-close--white xs-p-20 xs-absolute xs-r-20 xs-t-10 close-order-datetime"
                  @click="$emit('close')"></button>
          <div class="text-center">
            <div class="col-md-6 col-xs-12">
              <i class="fa fa-calendar xs-mr-5 fa-o-before-20" style="width: 20px;height: 20px"> </i>
              <select class="select-popup-date-time t22" data-synth="choice-day" v-model="choiceDay">
                <option class="dropdown-item" v-for="(item, index) in openingDateTime" :value="index">{{ item.label }}
                </option>
              </select>
            </div>

            <div class="col-md-6 col-xs-12">
              <i class="fa fa-clock-o xs-mr-5 fa-o-before-20" style="width: 20px;height: 20px"></i>
              <select class="select-popup-date-time t22" data-synth="choice-hour" v-model="choiceHour">
                <option class="dropdown-item" v-for="hour in hours" :value="hour">{{ hour == $t('asap') ? $t('asap')  : convertHour(hour) }}</option>
              </select>
            </div>
            <div class=" pt-100 action-save-date-time">
              <button style="width: 45%;margin: auto;" type="button" data-synth="save-date-time"
                      class="button button--primary xs-text-center save-date-time" @click="setTime()">
                {{$t('modal.set_order_time')}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import moment from 'moment-timezone'
import _ from 'lodash'
import main from '../../main.js'
import {mapGetters} from 'vuex'

export default {
  props: ['outletId', 'outletDataZip', 'outletDataLocalization', 'orderType', 'hideASAP', 'message'],
  comments: {},
  beforeCreate: function () {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-asap');
  },
  beforeDestroy: function () {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('modal-asap');
  },
  created(){
    this.callEventBus(this.$const.EVENT_BUS.LOADING, true)
  },
  mounted() {
    let data = {
      'id': 'delivery-time',
      'name': 'delivery-time',
      'type': 'tool',
      'placement': 'floating',
      'items': [
        {
          'id': 'delivery-time-1',
          'type': 'tool',
          'position': '1'
        }
      ],
      'assets': {
        'tools': [
          {
            'id': 'delivery-time-1',
            'type': 'selector',
            'name': 'delivery-time',
            'attributes': {
              'function': 'select-delivery-slot'
            }
          }
        ]
      }
    }
    this.eventTrackingModuleLoad(data)
    this.$disposition.init(this)
    this.init()
  },
  data() {
    return {
      selected: null,
      timezone: null,

      choiceDay: '',
      choiceHour: '',
      openingDateTime: [],
      hours: [],
      initCompleted: false
    }
  },
  watch: {
    choiceDay: function (newValue, oldValue) {
      this.hours = typeof this.openingDateTime[newValue] !== 'undefined' ? this.openingDateTime[newValue].data : []

      let exist = this.hours.includes(this.choiceHour)
      if(!exist){
        this.choiceHour = this.hours[0]
      }

      if (oldValue == '') {
        let h = this.$disposition.getByKey(this, 'collectionTime', '')
        if (h != '' && this.$disposition.getByKey(this, 'advanceOrder', '') == 1) {
          if (_.indexOf(this.hours, h.pickedHours) >= 0) {
            this.choiceHour = h.pickedHours
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isValidOrderTime: 'isValidOrderTime'
    })
  },
  methods: {
    /**
     * Init data after load page
     * */
    init() {
      let id = 0
      if (typeof this.outletId !== 'undefined' && this.outletId) {
        id = this.outletId
      } else if (!this.empty(this.$disposition.getByKey(this, 'outletId', ''))) {
        id = this.$disposition.getByKey(this, 'outletId', '')
      }
      var vm = this
      let order_type = this.orderType || this.$disposition.getByKey(this, 'orderType', 'D')
      this.axios.apiOutlet.getOutletOpeningHours({outletId: id, order_type: order_type.charAt(0)}, function (response) {
        let items = response.data.data.items
        if (!vm.empty(items)) {
          vm.timezone = items[0].info.timezone
          vm.setData(items)
        } else {
          vm.initCompleted = true
          vm.callEventBus(vm.$const.EVENT_BUS.LOADING, false)
        }
      })
    },
    isClosedForTheDay() {
      return _.isEmpty(this.openingDateTime) && this.initCompleted
    },
    setData(data) {
      let vm = this
      this.openingDateTime = {}

      let defaultDate = this.$disposition.getByKey(this, 'collectionTime', '')
      let order_type = this.orderType || this.$disposition.getByKey(this, 'orderType', 'D')
      let now = moment.tz(vm.timezone)
      let tomorrow = moment.tz(vm.timezone).add(1, 'days')
      let startTimeOrg = {}
      let hourNextDate = []

      for (let _d in data) {
        let obTime = []

        let date = moment.utc(data[_d].date)
        if ((date.dayOfYear() >= now.dayOfYear()) &&
            (date.year() >= now.year())) {
          let dateFormat = date.format('YYYY-MM-DD')
          let info = data[_d].info
          for (let e of info.business_date) {
            if (e.start && e.end) {
              if(hourNextDate.length > 0) {
                obTime = _.cloneDeep(hourNextDate)
              }
              hourNextDate = []
              let info_interval = e.interval
              let info_quoteTime = e.quote_time

              let startTime = this.convertUTCToTimeZone(e.start, vm.timezone)
                // .add(info_quoteTime, 'minutes')
              let endTime = this.convertUTCToTimeZone(e.end, vm.timezone)
                // .add(-info_quoteTime, 'minutes')

              // startTime.year(date.get('year')).month(date.get('month')).date(date.get('date'))
              // endTime.year(date.get('year')).month(date.get('month')).date(date.get('date'))
              if (endTime.format('HH:mm:ss') == '00:00:00') {
                endTime.set('hour', 23)
                endTime.set('minute', 55)
                endTime.set('second', 59)
              }

              let startTimeOrg = _.cloneDeep(startTime)
              let is24Hour = endTime.diff(startTime, 'hours') >= 23

              // if (order_type && order_type.toUpperCase() == vm.$const.CARRYOUT) {
              //   info.quote_time = vm.$const.QUOTE_TIME_CARRYOUT
              // }

              //TODAY
              let nextMinute = null
              if (now.format('YYYY-MM-DD') == date.format('YYYY-MM-DD')) {
                if (now.isAfter(startTime)) {
                  startTime = moment.tz(vm.timezone).add(info_quoteTime, 'minutes')
                }
              }
              let intervalValue = vm.getInterval(info, startTime, order_type)
              if (intervalValue != null) {
                if (intervalValue == -1) {
                  intervalValue = info_interval
                }
              } else {
                intervalValue = info_interval
              }

              nextMinute = (intervalValue * (Math.floor(startTime.get('minute') / intervalValue)))
              // eslint-disable-next-line no-unmodified-loop-condition
              while (nextMinute < startTime.get('minute') && intervalValue > 0) {
                nextMinute += intervalValue
              }
              startTime.minute(nextMinute)

              //Check start & end are same date
              let check_start = _.cloneDeep(startTime)
              let check_end = _.cloneDeep(endTime)
              let isSameDate = check_start.format('YYYY-MM-DD') == check_end.format('YYYY-MM-DD') ? true : false

              while (startTime.isSameOrBefore(endTime)) {
                let currentFormat = startTime.format('HH:mm');
                let interval = vm.getInterval(info, startTime, order_type);
                let isAdd = true;

                if (interval != null) {
                  if (interval == -1) {
                    isAdd = false
                    interval = info_interval
                  }
                } else {
                  interval = info_interval
                }

                // expired time (eg: 23:45 - 00:00)
                let nextTime = this._.cloneDeep(startTime)
                nextTime.add(interval, 'minutes')
                if (!nextTime.isBefore(endTime)) {
                  isAdd = false
                }
                // end expired time
                if (startTime.isBefore(endTime)) {
                    obTime.push(currentFormat)
                } else {
                    if(isSameDate)
                      obTime.push(currentFormat)
                    else
                      hourNextDate.push(currentFormat)
                }
                startTime = startTime.add(interval, 'minutes')
              }
              
              if (!this.empty(obTime)) {
                vm.setDateTime(dateFormat, obTime,
                  now.format('YYYY-MM-DD'),
                  tomorrow.format('YYYY-MM-DD'), startTimeOrg, is24Hour)
              }
            }
          }
        }
      }

      this.setDefaultChosen(defaultDate)
      this.callEventBus(this.$const.EVENT_BUS.LOADING, false)
      this.initCompleted = true
    },
    getInterval(data, currentTime, order_type) {
      let vm = this
      let interval = null;

      if (data.advance_duration && data.advance_duration.length) {
        for (let i in data.advance_duration) {
          let item = data.advance_duration[i];
          let date = moment.utc(item.date).tz(vm.timezone)
          let closeTime = this.convertUTCToTimeZone(item.date + ' ' + item.closing, vm.timezone);
          let openTime = this.convertUTCToTimeZone(item.date + ' ' + item.opening, vm.timezone);
          closeTime.year(date.get('year')).month(date.get('month')).date(date.get('date'))
          openTime.year(date.get('year')).month(date.get('month')).date(date.get('date'))

          if (closeTime.format('HH:mm:ss') == '00:00:00') {
            closeTime.set('hour', 23)
            closeTime.set('minute', 55)
            closeTime.set('second', 59)
          }
          if (currentTime.isSameOrAfter(openTime) && currentTime.isBefore(closeTime)) {
            if (order_type == vm.$const.DELIVERY) {
              interval = item.duration.D
            } else {
              interval = item.duration.C
            }
            break;
          }
        }
      }

      return interval;
    },
    setDateTime(obDate, obTime, today, tomorrow, startTimeOrigin, is24Hour) {
      let vm = this

      let label = ''
      if(obDate == today){
        label = this.$t('today')
      }else if(obDate == tomorrow){
        label = this.$t('tomorrow')
      }else {
        label = obDate
      }

      if (!this.openingDateTime[obDate]) {
        this.openingDateTime[obDate] = {'label': label, 'data': obTime}
      } else {
        let oldData = this.openingDateTime[obDate].data
        let newData = obTime
        this.openingDateTime[obDate].data = oldData.concat(newData)
      }

      let time = moment.tz(this.timezone)
      this.openingDateTime[obDate].data = this.openingDateTime[obDate].data.filter(x => {
        return time.isSameOrBefore(moment.tz(`${obDate} ${x}`, 'YYYY-MM-DD HH:mm', vm.timezone))
      }).sort()
      
      if (this.openingDateTime[obDate].data.length <= 0) {
        delete this.openingDateTime[obDate]
      } else {
        let hide = false
        if (this.hideASAP && this.hideASAP == 'true') {
          hide = true
        }
        if (obDate == today && !hide) {
          // check hide asap when asap time not available to order
          let timestart = startTimeOrigin.add(1, 'day') // for some reason, the start date is in the future, so minus 1 day

          if (timestart.isSameOrBefore(time)) {
            this.openingDateTime[obDate].data.unshift(this.$t('asap'))
          }
        } else {
          this.openingDateTime[obDate].data = this.openingDateTime[obDate].data.sort()
        }
      }

      let tOpeningDateTime = {}
      if (this.openingDateTime[today]) {
        tOpeningDateTime = Object.assign(tOpeningDateTime, {
          [today]: this.openingDateTime[today]
        })
      }
      // FIXME: As per PL-2353
      // if (this.openingDateTime[tomorrow]) {
      //   tOpeningDateTime = Object.assign(tOpeningDateTime, {
      //     [tomorrow]: this.openingDateTime[tomorrow]
      //   })
      // }
      tOpeningDateTime[today].data = _.uniq(tOpeningDateTime[today].data)
      this.openingDateTime = tOpeningDateTime
    },
    /**
     * Set time default
     * */
    setDefaultChosen(defaultDate) {
      let flag = false
      if (this.$disposition.getByKey(this, 'advanceOrder', '') == 1) {
        for (let date in this.openingDateTime) {
          if (date == defaultDate.pickedDay) {
            this.choiceDay = defaultDate.pickedDay
            this.hours = this.openingDateTime[date].data
            if (_.indexOf(this.openingDateTime[date].data, defaultDate.pickedHours) >= 0) {
              this.choiceHour = defaultDate.pickedHours
            } else {
              this.choiceHour = this.openingDateTime[date].data[0]
            }
            flag = true
            break
          }
        }
      }

      if (!flag) {
        for (let date in this.openingDateTime) {
          this.choiceDay = date
          this.hours = this.openingDateTime[date].data
          this.choiceHour = this.openingDateTime[date].data[0]
          flag = true
          break
        }
      }
    },
    /**
     * Set Collection time and redirect page
     * */
    setTime: function () {
      var timepicker = {'pickedDay': this.choiceDay, 'pickedHours': this.choiceHour}
      let data = this.$disposition.get(this)
      data.collectionTime = timepicker
      data.collectionTimeText = this.choiceDay + ' ' + this.choiceHour
      let time = this.choiceHour.split(' - ')
      if (time.length > 1) {
        time = time[1]
      } else if (time.length > 0) {
        time = time[0]
      }
      data.collectionTimeValue = this.choiceDay + ' ' + time
      data.timezone = this.timezone
      data.advanceOrder = 1
      if (this.choiceHour == this.$t('asap')) {
        data.advanceOrder = 0
      }
      this.$disposition.set(this, data)
      this.$emit('close')
      // call api to update order time
      this.$cart.updateOrderTime(this, {'order_time': this.getTimePickerDate(this)})
      this.$root.$emit('collectionTimeUpdate')
      if (this.outletDataZip) {
       this.$root.$emit('onCheckValidOutletZipCode', this.outletDataZip)
      }
      if (this.outletDataLocalization) {
        this.$root.$emit('onCheckValidOutlet', this.outletDataLocalization)
      }

      this.callEventBus(this.$const.EVENT_BUS.LOAD_CATEGORY, true)
    }
  }
}
</script>
<style scoped>
  select {
    border: none;
    background: transparent;
    /*background-color: blue;*/
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-top: 0px;
    background-size: 20px;
    color: #ffffff !important;
  }

  select.select-popup-date-time .dropdown-item {
    font-size: 15px !important;
  }

  .select-popup-date-time {
    border: 0;
    border-bottom: 1px solid #999;
    background: none;
    color: #fff !important;
    width: 79%;
  }

  .fa {
    color: #fff;
  }
</style>