const Cart = {
  install (Vue, options = {}) {
    Vue.prototype.$cart = {
      init: function (vm) {
        vm.cart = this.get(vm)
      },
      // get cart
      get: function (vm) {
        // vm.$session.set('cart', "")
        let cart = vm.$session.get('cart')
        if (!cart || vm.empty(cart)) {
          cart = {
            'uuid': '',
            'customer_id': null,
            'tax_percent': 0,
            'tax_total': 0,
            'total': 0,
            'sub_total': 0,
            'data': []
          }
        }
        return cart
      },
      getDetail: function (vm) {
        let cart = this.get(vm)
        let self = this

        if (cart.uuid) {
          let data = {'cart_id': cart.uuid}
          vm.axios.apiCart.getCart(data,
            function (response) {
              cart = response.data.data.item
              self.commit(vm, cart)
            }, function (error) {
              self.commit(vm, {})
            }
          )
        }
      },
      // insert item cart
      insert: function (vm, item) {
        let cart = this.get(vm)
        let self = this

        if (cart.uuid) {
          item.group.cart_id = cart.uuid
        }

        item.group.advance_order = vm.$disposition.getByKey(vm, 'advanceOrder', 0)
        item.group.order_time = vm.convertDateTimeToUTC(item.group.order_time)

        // Send to API
        // Received response from API and set for cart
        var title = vm.$t('cart.add_success')
        var message = vm.$t('cart_invalid_selling_time') + (item.selling_start ? vm.convertUTCToClientTime(item.selling_start).format('YYYY-MM-DD HH:mm:ss') : '')
        var message1 = vm.$t('cart_invalid_lunch_time')
        var message2 = vm.$t('cart_invalid_hut_day')
        var message3 = vm.$t('cart_invalid_order_time')
        var message4 = vm.$t('cart_invalid_open_time')
        var message5 = vm.$t('cart_invalid_min_cart')
        var message6 = vm.$t('cart_empty')
        var message7 = vm.$t('cart_invalid_current_order_time')
        vm.axios.apiCart.addToCart(item,
          function (response) {
            cart = response.data.data.item
            self.commit(vm, cart)
            vm.$notify({
              type: 'success',
              title: title
            })
          }, function (data) {
            switch (data.error.code) {
              case vm.$const.CART_CODE.SELLINGTIME:
                vm.$notify({
                  type: 'error',
                  title: message
                })
                break
              case vm.$const.CART_CODE.INVALIDHUTDAY:
                vm.$notify({
                  type: 'error',
                  title: message2
                })
                break
              case vm.$const.CART_CODE.INVALIDLUNCHTIME:
                vm.$notify({
                  type: 'error',
                  title: message1
                })
                break
              case vm.$const.CART_CODE.NOTFOUND:
                self.commit(vm, [])
                vm.$root.$emit('onRedirectToHome')
                break
              case vm.$const.CART_CODE.INVALIDORDERTIME:
                vm.$notify({
                  type: 'error',
                  title: message3
                })

                vm.callEventBus(vm.$const.EVENT_BUS.SHOW_ASAP, true)
                break
              case vm.$const.CART_CODE.INVALIDOPENTIME:
                vm.$notify({
                  type: 'error',
                  title: message4
                })

                vm.callEventBus(vm.$const.EVENT_BUS.SHOW_ASAP, true)
                break
              case vm.$const.CART_CODE.INVALIDMINCART:
                vm.$notify({
                  type: 'error',
                  title: message5
                })
                break
              case vm.$const.CART_CODE.EMPTYCART:
                vm.$notify({
                  type: 'error',
                  title: message6
                })
                break
              case vm.$const.CART_CODE.INVALIDCURRENTORDERTIME:
                vm.$notify({
                  type: 'error',
                  title: message7
                })

                vm.callEventBus(vm.$const.EVENT_BUS.SHOW_ASAP, true)
                break
              default:
                break
            }
          }
        )
      },
      updateCart: function (vm, item) {
        let cart = this.get(vm)
        let self = this

        if (cart.uuid) {
          item.group.cart_id = cart.uuid
        }

        item.group.advance_order = vm.$disposition.getByKey(vm, 'advanceOrder', 0)
        item.group.order_time = vm.convertDateTimeToUTC(item.group.order_time)

        // Send to API
        // Received response from API and set for cart
        var title = vm.$t('cart.update_success')
        var message = vm.$t('cart_invalid_selling_time') + (item.selling_start ? vm.convertUTCToClientTime(item.selling_start).format('YYYY-MM-DD HH:mm:ss') : '')
        var message1 = vm.$t('cart_invalid_lunch_time')
        var message2 = vm.$t('cart_invalid_hut_day')
        var message3 = vm.$t('cart_invalid_order_time')
        var message4 = vm.$t('cart_invalid_open_time')
        var message5 = vm.$t('cart_invalid_min_cart')
        var message6 = vm.$t('cart_empty')
        var message7 = vm.$t('cart_invalid_current_order_time')
        vm.axios.apiCart.updateItemCart(item,
          function (response) {
            cart = response.data.data.item
            self.commit(vm, cart)
            vm.$notify({
              type: 'success',
              title: title
            })
          }, function (data) {
            switch (data.error.code) {
              case vm.$const.CART_CODE.SELLINGTIME:
                vm.$notify({
                  type: 'error',
                  title: message
                })
                break
              case vm.$const.CART_CODE.INVALIDHUTDAY:
                vm.$notify({
                  type: 'error',
                  title: message2
                })
                break
              case vm.$const.CART_CODE.INVALIDLUNCHTIME:
                vm.$notify({
                  type: 'error',
                  title: message1
                })
                break
              case vm.$const.CART_CODE.NOTFOUND:
                self.commit(vm, [])
                vm.$root.$emit('onRedirectToHome')
                break
              case vm.$const.CART_CODE.INVALIDORDERTIME:
                vm.$notify({
                  type: 'error',
                  title: message3
                })
                break
              case vm.$const.CART_CODE.INVALIDOPENTIME:
                vm.$notify({
                  type: 'error',
                  title: message4
                })
                break
              case vm.$const.CART_CODE.INVALIDMINCART:
                vm.$notify({
                  type: 'error',
                  title: message5
                })
                break
              case vm.$const.CART_CODE.EMPTYCART:
                vm.$notify({
                  type: 'error',
                  title: message6
                })
                break
              case vm.$const.CART_CODE.INVALIDCURRENTORDERTIME:
                vm.$notify({
                  type: 'error',
                  title: message7
                })
                break
              default:
                break
            }
          }
        )
      },
      updateOrderTime: function (vm, item) {
        let cart = this.get(vm)
        if (!cart.uuid) {
          return false
        }
        item.cart_uuid = cart.uuid

        item.order_time = vm.convertDateTimeToUTC(item.order_time)

        vm.axios.apiCart.updateCartData(item,
          function (response) {
          }, function (data) {
          }
        )
      },
      updateCartUuid: function (vm) {
        let cart = this.get(vm)
        if (!cart.uuid) {
          return false
        }
        let item = {}
        item.cart_uuid = cart.uuid

        vm.axios.apiCart.updateCartData(item,
          function (response) {
          }, function (data) {
          }
        )
      },
      processApplyCoupon (vm, data, type) {
        let cart = this.get(vm)
        let self = this
        var title = vm.$t('cart.apply_coupon_success')
        vm.axios.apiCart.applyCoupon(data,
          function (response) {
            vm.$emit('close')
            cart = response.data.data.item
            let coupons = {
              menu: cart.free_items,
              coupon: data.coupon_code
            }
            let layer = {name: '', couponCode: data.coupon_code, price: 0}
            if (coupons.menu && coupons.menu.length > 0) {
              layer.name = coupons.menu[0].name
              layer.price = coupons.menu[0].price
            }
            vm.eventTrackingCoupon(vm, layer)
            self.commit(vm, cart, coupons)
            if (response.data.data.info.hasOwnProperty('topping') && !vm.empty(response.data.data.info.topping)) {
              let title = vm.$t('message')
              let name = response.data.data.info.topping.name
              let price = response.data.data.info.topping.price
              if (!isNaN(price)) {
                price = vm.formatNumDisplay(price)
              }
              if (name && name.length > 0) {
                name = name.join(', ')
              } else {
                name = ''
              }
              let message = vm.$t('coupon_free_topping', {name: name, price: price, currency_unit: vm.$const.CURRENCY})
              vm.$root.$emit('onShowNotification', title, message)
            }
            vm.$notify({
              type: 'success',
              title: title
            })
          }, function (error) {
            if (error.status === false) {
              let message = vm.$t('coupon_not_exist', {
                a_open: '<a href="/order/deals" class="underline">',
                a_close: '</a>'
              })
              // mapping message
              if (vm.lodash.keys(vm.$const.COUPON_ALLOW).indexOf(error.error.code) >= 0) {
                let coupon = vm.$const.COUPON_ALLOW[error.error.code]
                if (typeof coupon.fields !== 'undefined') {
                  let parames = {}
                  if (typeof coupon.currency !== 'undefined' && coupon.currency) {
                    parames.currency = vm.$const.CURRENCY
                  }
                  for (let i in coupon.fields) {
                    if (error.error.data.hasOwnProperty(coupon.fields[i])) {
                      let value = error.error.data[coupon.fields[i]]
                      if (!isNaN(value)) {
                        value = vm.formatNumDisplay(value)
                      }
                      parames[coupon.fields[i]] = value
                    }
                  }
                  message = vm.$t(vm.$const.MESSAGES[error.error.code], parames)
                } else {
                  message = vm.$t(vm.$const.MESSAGES[error.error.code])
                }
              }
              if (type === 'list') {
                vm.$notify({
                  type: 'error',
                  title: message
                })
              } else {
                vm.$store.commit('setCouponMessage', message)
              }
            } else {
              vm.$store.commit('setCouponMessage', '')
            }
          }
        )
      },
      applyCoupon: function (vm, data) {
        this.processApplyCoupon(vm, data, 'single')
      },
      applyCouponFromList: function (vm, data) {
        this.processApplyCoupon(vm, data, 'list')
      },
      removeCoupon: function (vm, data) {
        let cart = this.get(vm)
        let self = this
        var title = vm.$t('cart.remove_coupon_success')
        if (cart.uuid) {
          data.cart_id = cart.uuid
        }
        vm.axios.apiCart.removeCoupon(data,
          function (response) {
            vm.$emit('close')
            cart = response.data.data.item
            self.commit(vm, cart)
            vm.$notify({
              type: 'success',
              title: title
            })
          }
        )
      },
      updateQuantity: function (vm, data) {
        let cart = this.get(vm)
        let self = this
        var title = vm.$t('cart.update_quantity_success')
        if (cart.uuid) {
          data.cart_uuid = cart.uuid
        }
        vm.axios.apiCart.updateCartQuantity(data,
          function (response) {
            vm.$emit('close')
            cart = response.data.data.item
            self.commit(vm, cart)
            vm.$notify({
              type: 'success',
              title: title
            })
          }
        )
      },
      // remove item from cart
      remove: function (vm, id) {
        let data = {}
        let self = this
        let cart = this.get(vm)
        data['cart_id'] = cart.uuid
        let items = cart.data
        let newitems = []

        for (let i = 0; i < items.length; i++) {
          if (items[i].id != id) {
            newitems.push(items[i])
          }
        }
        cart.data = newitems

        // Send to API
        // Received response from API and set for cart
        data['item_id'] = id
        vm.axios.apiCart.removeCart(data, function (response) {
          cart = response.data.data.item
          self.commit(vm, cart)
        }, function (data) {
          if ([vm.$const.CART_CODE.NOTFOUND].indexOf(data.error.code) >= 0) {
            self.commit(vm, [])
            vm.$root.$emit('onRedirectToHome')
          }
        })
      },
      removeAll: function (vm, callback) {
        let data = {}
        let self = this
        let cart = this.get(vm)

        vm.axios.apiCart.removeCartByUuid(cart.uuid, function (response) {
          cart = response.data.data.item
          self.commit(vm, cart)

          if (callback) {
            callback(null, true)
          }
        }, function (data) {
          if ([vm.$const.CART_CODE.NOTFOUND].indexOf(data.error.code) >= 0) {
            self.commit(vm, [])

            if (callback) {
              callback(data.error.code, null)
            }
          }
        })
      },
      clearFreeItem: function (vm) {
        let cart = this.get(vm)
        delete cart['current_coupon_item']
        cart.free_items = []
        this.commit(vm, cart, null, true)
      },
      commit: function (vm, cart, coupon, hideTracking = false) {
        if (!cart || vm.empty(cart)) {
          vm.$session.set('cart', {})
          vm.$root.$emit('cartUpdate', this.get(vm))
        } else {
          cart.current_coupon_item = {}
          if (coupon && coupon.menu && coupon.menu.length > 0) {
            cart.current_coupon_item = {
              'uuid': coupon.menu[0].uuid,
              'is_combo': coupon.menu[0].is_combo || false,
              'coupon': coupon.coupon,
              'menu': coupon.menu
            }
          }
          vm.$session.set('cart', cart)
          vm.cart = cart
          vm.$root.$emit('cartUpdate', cart.data)
          if (coupon && coupon.menu && coupon.menu.length > 0) {
            vm.$root.$emit('couponItemImplement', cart.current_coupon_item)
          }
        }

        if (typeof hideTracking === 'undefined' || !hideTracking) {
          vm.eventTrackingLoadCart(vm)
        }
      },
      getToppingById: function (a) {
        let arr = []
        for (let i = 0; i < a.length; i++) {
          arr.push(a[i].id)
        }
        return arr
      }
    }
  }
}

export default Cart
