const Disposition = {
  install (Vue, options = {}) {
    Vue.prototype.$disposition = {
      // Init data
      init: function (vm) {
        vm.disposition = this.get(vm)
      },
      // Get disposition
      get: function (vm) {
        let disposition = vm.$session.get('disposition')
        if (!disposition) {
          disposition = {}
        }
        return disposition
      },
      getByKey: function (vm, key, value) {
        let disposition = this.get(vm)
        if (typeof disposition[key] !== 'undefined') {
          let data = disposition[key]
          if (key === 'collectionTimeText') {
            let times = data.split(' ')
            if (times.length > 1) {
              data = times[0] + ' ' + times[1]
            }
          }

          return data
        } else if (value) {
          return value
        }
        return ''
      },
      // Remove session
      remove: function (vm) {
        vm.$session.remove('disposition')
        vm.disposition = this.get(vm)
      },
      // Set Disposition
      set: function (vm, info) {
        vm.$session.set('disposition', info)
        vm.disposition = this.get(vm)
      }

    }
  }
}
export default Disposition
