import Vue from 'vue'
import App from './App'
import Router from 'vue-router'
import routes from './router'
import constant from './constants'
import store from './store'
import i18n from './lang.js'
import Mixin from './mixins/index.js'
import disposition from './plugins/disposition.js'
import VModal from 'vue-js-modal'
import * as uiv from 'uiv'

import VueSession from 'vue-session'
import axios from './axios.js'
import VueAxios from 'vue-axios'
import cart from './plugins/cart.js'
import Notifications from 'vue-notification'
import lodash from 'lodash'
import VueLodash from 'vue-lodash'
import VueAuth from '@websanova/vue-auth'
import Nl2br from 'vue-nl2br'
import VueSanitize from 'vue-sanitize'

import browserDetect from "vue-browser-detect-plugin";
import appboy from "@braze/web-sdk";

Vue.use(Router)
Vue.router = new Router({
  scrollBehavior(to, from, savedPosition) {
    if (to.name === 'home') {
      return { x: 0, y: 0 }
    }
  },
  hashbang: false,
  mode: 'history',
  history: true,
  routes
}),
Vue.use(VueAxios, axios)
Vue.axios.defaults.headers.post['Content-Type'] = 'application/json'
Vue.axios.defaults.headers.common['Client'] = process.env.VUE_APP_CLIENT_ID
Vue.axios.defaults.headers.common['Lang'] = process.env.VUE_APP_DEFAULT_LANGUAGE
Vue.use(VueAuth, {
  auth: {
    request: function(req, token) {
      let info = JSON.parse(unescape(atob(token)))
      this.options.http._setHeaders.call(this, req, { 'x-token': info.token })
    },

    response: function(res) {
      if (typeof res.data.data !== 'undefined') {
        if (
          typeof res.data.data.item !== 'undefined' &&
          res.data.data.item != null &&
          res.data.data.item.token
        ) {
          let info = res.data.data.item
          return btoa(escape(JSON.stringify(info)))
        }
      }
    }
  },
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  refreshData: { enabled: false },
  loginData: {
    url: process.env.VUE_APP_version + '/product-customer/login',
    method: 'POST',
    redirect: true,
    fetchUser: false
  },
  fetchData: {
    enabled: false,
    success: function() {
      let info = Vue.auth.token()

      if (info) {
        info = JSON.parse(unescape(atob(info)))
      }
      Vue.auth.user(info)
    }
  },
  logoutData: {
    url: process.env.VUE_APP_version + '/product-customer/logout',
    method: 'POST',
    redirect: false,
    makeRequest: true
  },
  notFoundRedirect: {
    path: '/'
  },
  forbiddenRedirect: {
    path: '/error-404'
  },
  authRedirect: {
    enabled: false
  }
})
Vue.use(VueSanitize)

Vue.component('async-component', function(resolve) {
  require([
    '@/components/popup/ModalPizza.vue',
    '@/components/popup/ModalPizzaHalf.vue'
  ], resolve)
})

const options = { lodash: lodash }
Vue.config.productionTip = false
Vue.config.performance = true
Vue.config.devtools = true
// Vue.config.errorHandler = (err, vm, info) => ErrorHandler.write(err, vm, info)
// Vue.config.warnHandler = (msg, vm, trace)  => WarnHandler(msg, vm, trace)
Vue.prototype.$const = constant

Vue.mixin(Mixin)
Vue.use(disposition)
Vue.use(VueSession, { persist: true })
Vue.use(cart)
Vue.use(VueLodash, options)
Vue.use(uiv)
Vue.use(Notifications)
Vue.use(VModal, { dynamic: true, injectModalsContainer: true, dialog: true })
Vue.use(browserDetect);

/* Component Layouts */
Vue.component('main-layout', function(resolve) {
  require(['./layouts/Main.vue'], resolve)
})
Vue.component('home-layout', function(resolve) {
  require(['./layouts/Home.vue'], resolve)
})
Vue.component('simple-layout', function(resolve) {
  require(['./layouts/Simple.vue'], resolve)
})
Vue.component('combo-layout', function(resolve) {
  require(['./layouts/Combo.vue'], resolve)
})
Vue.component('home-landing-layout', function(resolve) {
  require(['./layouts/HomeLanding.vue'], resolve)
})
Vue.component('home-footer', function(resolve) {
  require(['./components/home/HomeFooter.vue'], resolve)
})
Vue.component('home-top', function(resolve) {
  require(['./components/home/HomeTop.vue'], resolve)
})
Vue.component('modal-notify', function(resolve) {
  require(['./components/popup/ModalNotify.vue'], resolve)
})
Vue.component('nl2br', Nl2br)

// Vue event bus
const EventBus = new Vue()
Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function() {
      return EventBus
    }
  }
})

/* eslint-disable no-new */
const main = new Vue({
  el: '#app',
  i18n,
  store,
  template: '<App/>',
  components: { App },
  render: h => h(App),
  router: Vue.router,
  mounted() {
    this.$cart.init(this)
    this.loadPage()
    document.dispatchEvent(new Event('render-event'))
  },
  watch: {
    $route(to, from) {
      if (to.name === 'huts' && to.params.outlet) {
        router.replace(
          to.params.outlet
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\s/g, '+')
        )
      }
    }
  },
  created: function() {
    // change languages
    this.$session.set('client', process.env.VUE_APP_CLIENT_ID)
    this.$session.set('google-key-api', process.env.VUE_APP_GOOGLE_API_KEY)
    this.$i18n.locale = this.$session.get('lang') || this.$i18n.locale
    if (!this.$session.get('lang')) {
      this.$session.set('lang', this.$i18n.locale)
    }
    Vue.axios.defaults.headers.common['Lang'] = this.$i18n.locale
    this.initializeBraze()
  },
  methods: {
    loadPage() {
      this.$cart.getDetail(this)
    },
    initializeBraze() {
      try {
        // initialize the SDK
        appboy.initialize(process.env.VUE_APP_KEY_BRAZE, {
          baseUrl: process.env.VUE_APP_BASE_URL_BRAZE,
          enableLogging: true
        });

        // Be sure to remove calls to appboy.display.automaticallyShowNewInAppMessages() 
        // from your code as noted in the steps above
        appboy.subscribeToInAppMessage(function(inAppMessage) {
          let shouldDisplay = true;

          if (inAppMessage instanceof appboy.InAppMessage) {
            // Read the key-value pair for msg-id
            const msgId = inAppMessage.extras["msg-id"];
            if (msgId) {
              // We don't want to display the soft push prompt to users on browsers that don't support push, or if the user
              // has already granted/blocked permission
              if (!appboy.isPushSupported() || appboy.isPushPermissionGranted() || appboy.isPushBlocked()) {
                shouldDisplay = false;
              }
              if (inAppMessage.buttons[0] != null) {
                // Prompt the user when the first button is clicked
                inAppMessage.buttons[0].subscribeToClickedEvent(function() {
                  appboy.registerAppboyPushMessages();
                });
              }
            }
          }

          // Display the message
          if (shouldDisplay) {
            appboy.display.showInAppMessage(inAppMessage);
          }
        });

        // start (or continue) a session
        appboy.openSession();
      } catch (error) {
        console.error('Error initial braze' + error);
      }
    }
  }
})
export default main
