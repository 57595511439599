import app from '../main'
import * as action from './actions'
import axios from 'axios'
import Vue from 'vue'

export const statelang = {
  lang: 'en'
}

export const mutations = {
  [action.SET_LANG] (state, payload) {
    if (app) {
      app.$i18n.locale = payload
      app.$session.set('lang', payload)
    }

    axios.defaults.headers.common['Lang'] = payload
    state.lang = payload
  },
  setCategoryUuid (state, categoryUuid) {
    state.category_uuid = categoryUuid
  },
  setSubCategoryUuid (state, subCategoryUuid) {
    state.sub_category_uuid = subCategoryUuid
  },
  [action.SET_LOADING] (state, payload) {
    state.loading = payload
  },
  [action.SET_USER_ACTION] (state, payload) {
    state.user_action = payload
  },
  [action.SET_SELECTED_PRODUCT] (state, product) {
    state.selectedProduct = product
  },
  setSelectedOrderTime (state, orderTime) {
    state.selectedOrderTime = orderTime
  },
  [action.SET_CATEGORY_LIST] (state, payload) {
    state.categoryList = payload
  },
  [action.SET_IS_VALID_ORDER_TIME] (state, isValid) {
    state.isValidOrderTime = isValid
  },
  [action.SET_SELECTED_CART] (state, product) {
    state.selectedCart = product
  },
  [action.SET_USER] (state, user) {
    state.user = user
  },
  [action.SET_SELECTED_CATEGORY] (state, category) {
    state.selectedCategory = category
  },
  [action.SET_SELECTED_CATEGORY_ITEMS] (state, items) {
    state.selectedCategoryItems = items
  },
  [action.SET_SELECTED_SUB_CATEGORY] (state, subCategory) {
    state.selectedSubCategory = subCategory
  },
  [action.SET_SELECTED_OUTLET] (state, payload) {
    state.selectedOutlet = payload
  },
  [action.SET_HUT_OUTLET_ID] (state, payload) {
    state.selectedHutOutletId = payload
  },
  [action.SET_SELECTED_HUT_OUTLET] (state, payload) {
    state.selectedHutOutlet = payload
  },
  [action.SET_DISPOSITION] (state, payload) {
    state.selectedDisposition = payload
  },
  [action.SET_UI_DIRECTION] (state, payload) {
    state.uiDirection = payload
  },
  setCouponMessage (state, message) {
    state.coupon_message = message
  },
  setMenuDeal (state, menuDeal) {
    state.menu_deal = menuDeal
  }
}

export const getters = {
  category_uuid: state => state.category_uuid,
  categoryList: state => state.categoryList,
  sub_category_uuid: state => state.sub_category_uuid,
  coupon_message: state => state.coupon_message,
  menuDeal: state => state.menu_deal,
  selectedCategory: state => state.selectedCategory,
  selectedSubCategory: state => state.selectedSubCategory,
  selectedProduct: state => state.selectedProduct,
  selectedCart: state => state.selectedCart,
  selectedOrderTime: state => state.selectedOrderTime,
  selectedOutlet: state => state.selectedOutlet,
  selectedHutOutletId: state => state.selectedHutOutletId,
  selectedHutOutlet: state => state.selectedHutOutlet,
  isValidOrderTime: state => state.isValidOrderTime,
  selectedDisposition: state => state.selectedDisposition,
  uiDirection: state => state.uiDirection,
  user: state => {
    if (state.user) {
      return state.user
    } else {
      return Vue.auth.user()
    }
  },
  lang: state => {
    if (state.lang) {
      return state.lang
    } else {
      return app.$i18n.locale || app.$session.get('lang')
    }
  },
  langList: state => {
    return state.langList
  }
}
