import main from './main.js'
import apiMenu from '@/api/Menu.js'
import apiOutlet from '@/api/Outlet.js'
import apiCustomer from '@/api/Customer.js'
import apiCart from '@/api/Cart.js'
import apiOrder from '@/api/Order.js'
import apiTenant from '@/api/Tenant.js'
import apiPayment from '@/api/Payment.js'
import apiCampaign from '@/api/Campaign.js'
import apiCoupon from '@/api/Coupon.js'
import apiSlotGame from '@/api/SlotGame.js'
import apiBanners from '@/api/Banners.js'
import apiContent from '@/api/Content.js'
import apiTopic from '@/api/Topic.js'
import apiGeocoding from '@/api/Geocoding'
import _ from 'lodash'

var axios = require('axios')
axios.options = {
  flag: false, // false : not show ajax loader
  notify: false // false : not show notify error
}
axios.defaults.baseURL = process.env.VUE_APP_host
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.interceptors.request.use(
  success => {
    if (axios.options.flag) {
      main.callEventBus(main.$const.EVENT_BUS.LOADING, true)
    }

    return success
  },
  error => {
    if (axios.options.flag) {
      main.callEventBus(main.$const.EVENT_BUS.LOADING, false)
    }

    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  success => {
    if (axios.options.flag) {
      main.callEventBus(main.$const.EVENT_BUS.LOADING, false)
    }

    axios.options.flag = true
    axios.options.notify = true

    return success
  },
  error => {
    if (axios.options.flag) {
      main.callEventBus(main.$const.EVENT_BUS.LOADING, false)
    }

    axios.options.flag = true
    if (error.response.data.error.code === main.$const.CUSTOMER_CODE.INVALID) {
      main.$auth.logout()
    }

    // convert message
    let message = error.response.data.error.message
    if (_.keys(main.$const.MESSAGES).indexOf(error.response.data.error.code) >= 0) {
      message = main.$t(main.$const.MESSAGES[error.response.data.error.code])
      error.response.data.error.message = message
    }
    // FIXME: Suppress API error message notification on UI
    if ([main.$const.CART_CODE.SELLINGTIME, main.$const.CART_CODE.INVALIDHUTDAY, main.$const.CART_CODE.INVALIDLUNCHTIME, main.$const.CART_CODE.NOTFOUND].indexOf(error.response.data.error.code) < 0) {
      console.error(`[NOTICE] ${message}`)
      // if (axios.options.notify) {
      //   main.$notify({
      //     type: 'error',
      //     title: message
      //   })
      // }
    }
    axios.options.notify = true

    return Promise.reject(error)
  }
)

axios.apiMenu = apiMenu
axios.apiOutlet = apiOutlet
axios.apiCustomer = apiCustomer
axios.apiCart = apiCart
axios.apiOrder = apiOrder
axios.apiTenant = apiTenant
axios.apiOrder = apiOrder
axios.apiPayment = apiPayment
axios.apiCampaign = apiCampaign
axios.apiCoupon = apiCoupon
axios.apiSlotGame = apiSlotGame
axios.apiBanners = apiBanners
axios.apiContent = apiContent
axios.apiTopic = apiTopic
axios.apiGeocoding = apiGeocoding

export default axios
