export default {
  CURRENCY: process.env.VUE_APP_CURRENCY || '$',
  CURRENCY_CODE: 'MXN',
  // COUNTRY_CODE: "MY",
  CURRENCY_FORMAT: 2, // 0.00
  CURRENCY_RGX: /(\d)(?=(\d\d\d)+(?!\d))/g,
  MAX_CART_VALUE: 2000,
  CURRENCY_DEC_POINT: '$1,',
  COUNTRY_CODE: 'MX', // "AE",
  COUNTRY_CODE_COMPONENT: 'country:mx', // "AE",
  GOOGLE_MAP_API_KEY: process.env.VUE_APP_GOOGLE_MAP_API_KEY, // "AE",
  TAX_RATE: 6,
  PAYMENT_METHOD_MAYBANK_CREDIT_CARD: 'soft_bank',
  PAYMENT_METHOD_CREDIT_CARD: 7,
  PAYMENT_METHOD_MAYBANK2U: 5,
  PAYMENT_METHOD_CASH_ON_DELIVERY: 'cash',
  PAYMENT_METHOD_CREDIT_ON_DELIVERY: 'eftpos',
  PAYMENT_METHOD_FOR_CASH_ON_DELIVERY: 1,
  PAYMENT_METHOD_MASTERPASS: 4,
  COPY_RIGHT: 'Copyright',
  COPY_RIGHT_MK: 'PIZZA HUT MEXICO LTD. All Rights Reserved.',
  TEL: '55 8250 5650',
  LIMIT_BIRTHDAY: 1900,
  MENU_DEFAULT: 'deals',
  MENU_LUNCH: 'lunch-time',
  MENU_HUT_DAY: 'hut-day',
  MENU_PIZZA: 'pizzas',
  MENU_COUPON: 'coupons',
  DELIVERY: 'DELIVERY',
  CARRYOUT: 'CARRYOUT',
  DEFAULT_CONFIG_APP: 'MAP',
  DEFAULT_CONFIG_APP_2: 'Localization',
  OPEN_TIME: '10:00:00',
  CLOSE_TIME: '23:00:00',
  DOMAIN_BETA: 'brbeta.phdvasia.com',
  SWIFTYPE_HOST: 'host-eypj92',
  SWIFTYPE_API_KEY: 'search-vksfirz45gsd5mq9vvd17nry',
  SWIFTYPE_ENGINE_NAME: 'phjjp',
  HALF_HALF_ID: 2,
  HALF_HALF_SET: {
    NORMAL: 0,
    LUNCH: 1,
    BOTH: 2
  },
  HALF_HALF_PRICE: 50,
  HALF_HALF_LUNCH_PRICE: 40,
  CARD_ITEM_TYPE: { SINGLE: 0, COMBO: 1, HALF: 2 },
  LUNCH_TIME_UUID: '48609434-44bc-3a02-9e23-d682dcbf4395',
  HALF_HALF_UUID: 'half-and-half',
  HALF_HALF_LUNCH_UUID: 'lunch-half-and-half',
  COUPON_UUID: '6d7faa2c-5cf4-11e9-8647-d663bd873d93',
  FACEBOOK_LOGIN: 1, // login with facebook
  GOOGLE_LOGIN: 2, // login with google +
  YAHOO_LOGIN: 3, // login with yahoo
  HUT_DAY_UUID: '8ff881a5-6cc1-4661-a678-bcf1e6e516bb',
  CATEGORY_TOPIC: 'campaigns',
  CATEGORY_COUPON: 'coupons',
  COUPON_ALLOW: {
    'CP-02': {},
    'CP-03': { fields: ['min_cart'], currency: true },
    'CP-04': {},
    'CP-05': {},
    'CP-07': {},
    'CP-08': {},
    'CP-09': {},
    'CP-10': {},
    'CP-11': {},
    'CP-12': {},
    'CP-13': {},
    'CP-14': {},
    'CP-16': {},
    'CP-17': {},
    'CP-18': {},
    'CP-19': {},
    'CP-20': {},
    'CP-21': {},
    'CP-22': {},
    'CP-23': {},
    'CP-24': {},
    'CP-25': { fields: ['min_cart'] }
  },
  CART_CODE: {
    INVALIDHUTDAY: 'ITEM-02',
    NOTFOUND: 'CART-01',
    INVALIDLUNCHTIME: 'ITEM-03',
    SELLINGTIME: 'SINGLE-06',
    INVALIDORDERTIME: 'HUT-01',
    INVALIDOPENTIME: 'HUT-02',
    INVALIDMINCART: 'CART-03',
    EMPTYCART: 'CART-04',
    INVALIDCURRENTORDERTIME: 'CART-07'
  },
  MESSAGES: {
    'ORDER-1': 'order.error_text_order_not_found',
    'ORDER-2': 'order.error_text_empty_cart_detail',
    'ORDER-3': 'order.error_text_empty_store_detail',
    'ORDER-4': 'order.error_text_single_invalid',
    'ORDER-5': 'order.error_text_not_update_use_coupon',
    'ORDER-6': 'order.error_text_apply_coupon_fail',
    'ORDER-7': 'order.error_text_not_add_item_cart',
    'ORDER-8': 'order.error_text_not_create_cart',
    'ORDER-9': 'order.error_text_invalid_order_time',
    'ORDER-10': 'order.error_text_empty_store_open_time',
    'ORDER-11': 'order.error_text_invalid_collection_time',
    'ORDER-12': 'order.error_text_store_close',
    COMMON: 'cart.error_text_invalid_cart_item',
    'HUT-01': 'cart.error_text_invalid_order_time',
    'HUT-02': 'cart.error_text_invalid_open_time',
    'HUT-03': 'cart.error_text_invalid_res_info',
    'HUT-04': 'cart.error_text_call_hut_api_error',
    'CART-01': 'cart.error_text_cart_not_found',
    'CART-02': 'cart.error_text_invalid_time_quote',
    'CART-03': 'cart.error_text_invalid_min_cart',
    'CART-04': 'cart.error_text_empty_cart',
    'CART-05': 'cart.error_text_invalid_outlet_min',
    'CART-07': 'cart.error_text_invalid_curr_order_time',
    'REWARD-01': 'cart.dont_have_coupon',
    'REWARD-02': 'cart.coupon_dont_contain_coupon',
    'REWARD-03': 'cart.can_not_get_coupon',
    'REWARD-04': 'cart.invalid_reward_item',
    'REWARD-05': 'cart.coupon_expired',
    'ITEM-02': 'cart.invalid_hutday',
    'ITEM-03': 'cart.invalid_lunchtime',
    'ITEM-04': 'cart.invalid_reward_item',
    'COMBO-01': 'cart.invalid_combo_uuid',
    'COMBO-02': 'cart.invalid_combo_response',
    'COMBO-03': 'cart.invalid_selling_date',
    'GROUP-01': 'cart.invalid_group_id',
    'GROUP-02': 'cart.invalid_item_id',
    'GROUP-03': 'cart.invalid_second_id',
    'GROUP-04': 'cart.invalid_topping',
    'GROUP-05': 'cart.invalid_first_id',
    'SINGLE-01': 'cart.invalid_product_id',
    'SINGLE-02': 'cart.invalid_single_response',
    'SINGLE-03': 'cart.invalid_second_id',
    'SINGLE-04': 'cart.invalid_topping',
    'SINGLE-05': 'cart.invalid_first_id',
    'SINGLE-06': 'cart.invalid_selling_date',
    'SINGLE-07': 'cart.invalid_open_time',
    'COUPON-01': 'cart.invalid_coupon',
    'COUPON-02': 'cart.broken_coupon_link',
    'COUPON-03': 'cart.invalid_coupon_apply',
    'COUPON-04': 'cart.invalid_payment_method',
    'CUS-100001': 'customer.token_invalid',
    'CUS-100002': 'customer.customer_not_found',
    'CUS-100003': 'customer.email_exist',
    'CUS-100004': 'customer.can_not_create',
    'CUS-100005': 'customer.email_exist', // can_not_update
    'CUS-100006': 'customer.auth_fail',
    'CUS-100007': 'customer.using_old_password',
    'CUS-100008': 'customer.can_not_create_location',
    'CUS-100009': 'customer.can_not_update_location',
    'CUS-100010': 'customer.otp_sent',
    'CUS-100011': 'customer.opt_invalid',
    'CUS-100012': 'customer.request_id_invalid',
    'CUS-100013': 'customer.otp_required',
    'CUS-100014': 'customer.data_invalid',
    'CUS-100015': 'customer.email_id_not_found',
    'CUS-100016': 'customer.can_not_create_contact',
    'CUS-100017': 'customer.location_notfound',
    'CP-01': 'coupon.exception',
    'CP-02': 'coupon.already_apply',
    'CP-03': 'coupon.minimun_amount_apply',
    'CP-04': 'coupon.invalid_open_time',
    'CP-05': 'coupon.invalid_order_type',
    'CP-06': 'coupon.invalid_sale_channel',
    'CP-07': 'coupon.login_to_use',
    'CP-08': 'coupon.invalid_this_user',
    'CP-09': 'coupon.invalid_this_outlet',
    'CP-10': 'coupon.invalid_multiple',
    'CP-11': 'coupon.invalid_with_menu_item',
    'CP-12': 'coupon.coupon_not_found',
    'CP-13': 'coupon.invalid_order_time',
    'CP-14': 'coupon.only_cash_credit',
    'CP-15': 'coupon.missing_params_validation',
    'CP-16': 'coupon.quality_not_sufficient',
    'CP-17': 'coupon.only_future',
    'CP-18': 'coupon.only_mon_tue_day',
    'CP-19': 'coupon.only_hut_day',
    'CP-20': 'coupon.only_lunctime',
    'CP-21': 'coupon.item_not_applicable',
    'CP-22': 'coupon.invalid_min_amount_apply_coupon',
    'CP-23': 'coupon.invalid_limit_coupon',
    'CP-24': 'coupon.coupon_cp_24',
    'CP-25': 'coupon.minimun_item',
    'CPH-01': 'coupon.history_exception',
    'CPH-02': 'coupon.missing_params_history',
    'GA-01': 'coupon.game_unavailable',
    'GA-02': 'coupon.game_setting_empty',
    'GA-03': 'coupon.game_exceeded_play_time',
    'HUTFE-10': 'localization.notopen',

    'HUTFE-0': 'hutfe.error_text_fe_invalid_input_data',
    'HUTFE-1': 'hutfe.error_text_fe_store_not_found',
    'HUTFE-2': 'hutfe.error_text_fe_order_time_in_past',
    'HUTFE-3': 'hutfe.error_text_fe_info_accurate',
    'HUTFE-4': 'hutfe.error_text_fe_temporarily_unavailable',
    'HUT-FE-004': 'hutfe.error_text_fe_opentimes',
    'HUTFE-5': 'hutfe.error_text_fe_service_end',
    'HUTFE-6': 'hutfe.error_text_fe_address_code_not_found',
    'HUTBE-1': 'hutbe.error_text_be_store_not_found',
    'HUTBE-2': 'hutbe.error_text_be_store_group_not_found',
    'HUTBE-3': 'hutbe.error_text_be_file_invalid',
    'HUTBE-4': 'hutbe.error_text_be_store_id_invalid',
    'HUTBE-5': 'hutbe.error_text_be_select_file',
    'HUTBE-6': 'hutbe.error_text_be_file_not_found',
    'HUTBE-7': 'hutbe.error_text_be_temporarily_unavailable',
    'HUTBE-8': 'hutbe.error_text_be_service_end',
    'HUTBE-9': 'hutbe.error_text_be_call_internal_error',
    'HUTBE-0': 'hutbe.error_text_be_invalid_data'
  },
  LOCALIZATION_CODE: { UNAVALIABLE: 'HUTFE-4', NOTOPEN: 'HUTFE-10' },
  CUSTOMER_CODE: { INVALID: 'CUS-100001' },
  COUPON_TYPE_ITEM: 1,
  COUPON_TYPE_UNIT: 2,
  COUPON_TYPE_PERCENT: 3,
  COUPON_TYPE_FREE_DELIVERY: 4,
  IMG_PIZZA_DEFAULT:
    'https://static.phdvasia.com/mx/img/default_item.png',
  IMG_CRUST_DEFAULT:
    'https://static.phdvasia.com/mx/img/default_crust.png',
  FLAG_DELIVERY_FEE: true,
  COUPON_PAGE_REQUIRED_LOGIN: true,
  LANG_ES: 'es',
  LANG_EN: 'en',
  DATA_LAYER: {
    PLATFORM: {
      type: 'website',
      name: 'phdv',
      environment: '',
      localeCountry: 'es',
      localeLanguage: 'es',
      currency: 'MXN'
    }
  },
  DEFAULT_SELECTED_BIRTH_YEAR: 20,
  HEADER_MENU: {
    CAMPAIGN: 'campaigns',
    CAMPAIGN_DETAIL: 'campaign-detail',
    TOPIC: 'topic',
    TOPIC_DETAIL: 'topic-detail',
    CATEGORY: 'category',
    COUPON: 'coupons',
    HALF_HAlF: 'half-half',
    PIZZA: 'pizzas',
    LUNCH_SET: 'lunch-set',
    COMBO_DETAIL: 'combo-detail'
  },
  PIZZA_SLUG: 'pizzas',
  ORDER_STATUS: {
    ERROR: [4, 22],
    STARTING: [1, 2, 25],
    RECEIVED: [3, 10, 11, 12, 13, 14, 15, 27],
    COOKING: [5],
    DELIVERING: [6],
    COMPLETED: [7, 9],
    CANCELED: [8],
    READY: [16, 17, 18, 19, 20]
  },
  ORDER_STATUS_IMAGE: {
    ERROR: [4, 22],
    STARTING: {
      GIF: process.env.VUE_APP_STATIC_URL + '/icons/tracker-ani_01-chosen-alpha.gif',
      PNG: process.env.VUE_APP_STATIC_URL + '/icons/tracker-ani_01-chosen-alpha.png'
    },
    RECEIVED: {
      GIF: process.env.VUE_APP_STATIC_URL + '/icons/tracker-ja-accepted.gif',
      PNG: process.env.VUE_APP_STATIC_URL + '/icons/tracker-ja-accepted.png'
    },
    COOKING: {
      GIF: process.env.VUE_APP_STATIC_URL + '/icons/tracker-making.gif',
      PNG: process.env.VUE_APP_STATIC_URL + '/icons/tracker-making.png'
    },
    DELIVERING: {
      GIF: process.env.VUE_APP_STATIC_URL + '/icons/tracker-delivery.gif',
      PNG: process.env.VUE_APP_STATIC_URL + '/icons/tracker-delivery.png'
    },
    COMPLETED: {
      GIF: process.env.VUE_APP_STATIC_URL + '/icons/tracker-collection.gif',
      PNG: process.env.VUE_APP_STATIC_URL + '/icons/tracker-collection.png'
    },
    CANCELED: {
      GIF: process.env.VUE_APP_STATIC_URL + '/icons/tracker-ani_01-chosen-alpha.gif',
      PNG: process.env.VUE_APP_STATIC_URL + '/icons/tracker-ani_01-chosen-alpha.png'
    },
    READY: {
      GIF: process.env.VUE_APP_STATIC_URL + '/icons/tracker-collection.gif',
      PNG: process.env.VUE_APP_STATIC_URL + '/icons/tracker-collection.png'
    }
  },
  PHONE_MIN_LENGTH: 10,
  PHONE_MAX_LENGTH: 12,
  COUPON_TYPES: {
    PROMOTION: [4, 5, 6],
    DISCOUNT: [2, 3],
    FREE_GIFT: [1],
    CIRCLE_DISCOUNT: [5]
  },
  COUPON_IMAGES: {
    PROMOTION: {
      PT: process.env.VUE_APP_STATIC_URL + '/images/Promotion-JP.jpg',
      EN: process.env.VUE_APP_STATIC_URL + '/images/Promotions.png'
    },
    DISCOUNT: {
      PT: process.env.VUE_APP_STATIC_URL + '/images/Discount-JP.jpg',
      EN: process.env.VUE_APP_STATIC_URL + '/images/Discount.png'
    },
    FREE_GIFT: {
      PT: process.env.VUE_APP_STATIC_URL + '/images/Free-Gift-JP.jpg',
      EN: process.env.VUE_APP_STATIC_URL + '/images/Free Gift.png'
    }
  },
  QUOTE_TIME_CARRYOUT: 30,
  EVENT_BUS: {
    LOADING: 'LOADING',
    LOAD_CATEGORY: 'LOAD_CATEGORY',
    SHOW_ASAP: 'SHOW_ASAP',
    REDIRECT_TO_MENU_PAGE: 'REDIRECT_TO_CATEGORY_PAGE',
    REDIRECT_TO_ITEM_PAGE: 'REDIRECT_TO_ITEM_PAGE'
  },
  TOPPING: {
    // MAX_TOPPING: process.env.VUE_APP_MAX_TOPPING,
    // MAX_PER_TOPPING: process.env.VUE_APP_MAX_PER_TOPPING,
    MAX_EXTRA: 1,
    MAX_REMOVAL: 1,
    MAX_PER_TOPPING: 1,
    TYPE_BOTH: 0,
    TYPE_DEFAULT: 1,
    TYPE_EXTRA: 2,
    DEFAULT_IMAGE: 'https://static.phdvasia.com/br/menu/topping/desktop_thumbnail_5f55d01c-2762-4abe-ab9e-72a8b13ef13f.png'
  },
  HALF_HALF_IMAGES: {
    NORMAL_4: process.env.VUE_APP_STATIC_URL + '/images/half-and-half.png',
    NORMAL_2: process.env.VUE_APP_STATIC_URL + '/images/half-and-half-2.jpg',
    LUNCH_4: process.env.VUE_APP_STATIC_URL + '/images/Lunch_H&H.png',
    LUNCH_2: process.env.VUE_APP_STATIC_URL + '/images/Lunch_H&H_BG.jpg',
    CATEGORY: process.env.VUE_APP_STATIC_URL + '/images/half_half_category.png'
  },
  IMAGES: {
    HUT_TEXT_1: process.env.VUE_APP_STATIC_URL + '/images/Store-3.png',
    HUT_TEXT_2: process.env.VUE_APP_STATIC_URL + '/images/Store-2.png',
    HUT_TEXT_3: process.env.VUE_APP_STATIC_URL + '/images/Store-1.png',
    HUT_TEXT_4: process.env.VUE_APP_STATIC_URL + '/images/block-hutday-outlet.png'
  },
  HALF_HALF_EXCLUDE_STORE: [],
  NON_LOCALIZATION_CATEGORY: ['deals'],
  VIEW_MENU_DEFAULT: 'pizza-a-domicilio',
  SLUG_LIST: {
    PIZZA: ['pizza', 'pizzas'],
    DEAL: ['deals']
  },
  FORMAT_CODE: {
    UTF8: 'utf-8',
    JIS: 'shift-jis'
  },
  TIMEOUT_CART: process.env.VUE_APP_TIMEOUT_CART,
  ITEM_VIEW_MODE: 1, // 0 : DEFAULT , 1 : ONE TAP (AVAILABLE WITH NZ AND SG MARKET),
  COMBO_PRICE_VIEW_MODE: 0, // 0 : DEFAULT, 1 : SHOW BOTH [DISPLAY PRICE] AND [PRICE]
  CATEGORY_ITEM_VIEW_MODE: process.env.VUE_APP_CATEGORY_ITEM_VIEW_MODE,
  LOCALIZATION_TYPE: process.env.VUE_APP_LOCALIZATION_TYPE,
  LOCALIZATION_TYPES: {
    MAP: 'MAP',
    MAP_ASIA: 'MAP_ASIA',
    MAP_GOOGLE_API: 'MAP_GOOGLE_API'
  },
  DEFAULT_LANGUAGE: 'es',// process.env.VUE_APP_DEFAULT_LANGUAGE,
  CLIENT_ID: process.env.VUE_APP_CLIENT_ID,
  GOOGLE_API_KEY: process.env.VUE_APP_GOOGLE_API_KEY,
  LANGUAGES: {
    en: {
      image: require('@/assets/images/icons/en.png'),
      text: 'English'
    },
    es: {
      image: require('@/assets/images/icons/mexico@3x.png'),
      text: 'Español'
    }
  },
  SHOW_EMERGENCY_BANNER: false,
  ADYEN: {
    NAME: 'adyen',
    RESULT_CODE: {
      AUTHORISED: 'Authorised',
      CANCELLED: 'Cancelled',
      ERROR: 'Error',
      PENDING: 'Pending',
      RECEIVED: 'Received',
      REFUSED: 'Refused'
    },
    LANGUAGE_CODE: {
      ES: 'es-MX',
      EN: 'en-US'
    },
    STYLE_LINK: 'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/4.5.0/adyen.css',
    ENVIRONMENT: {
      TEST: 'test',
      LIVE: 'live-us'
    }
  }
}
