import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './assets/lang/en.json'
import es from './assets/lang/es.json'
import constants from './constants'

Vue.use(VueI18n)

const messages = {
  en,
  es
}
const i18n = new VueI18n({
  locale: constants.DEFAULT_LANGUAGE,
  messages,
  fallbackLocale: constants.DEFAULT_LANGUAGE
})

export default i18n
