const lodash = require('lodash')
const main = require('../main')

const ComboDetail = () => import('@/components/combo/ComboDetail.vue')
const Register = () => import('@/pages/Register.vue')
const ForgotPassword = () => import('@/pages/ForgotPassword.vue')
const SetNewPassWord = () => import('@/pages/SetNewPassword.vue')
const Profile = () => import('@/pages/Profile.vue')
const EditProfile = () => import('@/pages/EditProfile.vue')
const Ponta = () => import('@/pages/Ponta.vue')
const AddLocation = () => import('@/pages/Location.vue')
const Careers = () => import('@/pages/static/Career.vue')
const History = () => import('@/pages/History.vue')
const Post = () => import('@/pages/static/Post.vue')
const Crs = () => import('@/pages/static/Crs.vue')
const TestLocalization = () => import('@/pages/TestLocalization.vue')
const ResultLocalization = () => import('@/pages/ResultLocalization.vue')
const Confirmation = () => import('@/pages/Confirmation.vue')
const PaymentStatus = () => import('@/pages/PaymentStatus.vue')
const TopicList = () => import('@/pages/TopicList.vue')
const TopicDetail = () => import('@/pages/TopicDetail.vue')
const Campaign = () => import('@/pages/Campaign.vue')
const CouponAttachment = () => import('@/pages/CouponAttachment.vue')
const HomePage = () => import('@/pages/Home.vue')
const HutPage = () => import('@/pages/Hut.vue')
const Huts = () => import('@/components/partials/Huts.vue')
const CategoryPage = () => import('@/pages/Category.vue')
const ContactPage = () => import('@/pages/static/Contact.vue')
const LoginPage = () => import('@/pages/Login.vue')
const CheckoutPage = () => import('@/pages/CheckOut.vue')
const TrackerOrder = () => import('@/pages/TrackerOrder.vue')
const Tracking = () => import('@/pages/Tracking.vue')
const CreditCardPayment = () => import('@/pages/CreditCardPayment.vue')
const Faq = () => import('@/pages/static/Faq.vue')
const Terms = () => import('@/pages/static/Terms.vue')
const Privacy = () => import('@/pages/static/Privacy.vue')
const Conditions = () => import('@/pages/static/Conditions.vue')
const Promotions = () => import('@/pages/static/Promotions.vue')
const OnlineCheckoutPage = () => import('@/pages/OnlineCheckout.vue')

module.exports = [
  {
    path: '/huts',
    name: 'huts',
    meta: { layout: 'simple', key_title: '' },
    component: Huts
  },
  {
    path: '/hut/:outlet',
    name: 'hut',
    meta: { layout: 'simple' }, // home-landing
    component: HutPage
  },
  {
    path: '/order/:slug?/:sub?/:uuid?', //
    name: 'category',
    meta: { layout: 'main' },
    component: CategoryPage
  },
  {
    path: '/contact',
    name: 'contact',
    meta: { layout: 'simple', key_title: 'header.contact' },
    component: ContactPage
  },
  {
    path: '/login/:method?',
    props: true,
    name: 'login',
    component: LoginPage,
    meta: { layout: 'simple', key_title: 'header.login' }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { layout: 'simple', key_title: 'header.register', auth: false }
  },
  {
    path: '/getmypassword',
    component: ForgotPassword,
    meta: { layout: 'simple', key_title: 'header.reset_password' }
  },
  {
    path: '/setnewpassword/:token',
    component: SetNewPassWord,
    meta: { layout: 'simple', key_title: 'header.create_new_password' }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      layout: 'simple',
      key_title: 'header.my_account_information',
      auth: true
    }
  },
  {
    path: '/edit-profile',
    component: EditProfile,
    meta: {
      layout: 'simple',
      key_title: 'header.update_information',
      auth: true
    }
  },
  {
    path: '/ponta-attach',
    name: 'ponta-attach',
    component: Ponta,
    meta: { layout: 'simple', key_title: 'header.ponta', auth: true }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutPage,
    meta: { layout: 'simple', key_title: 'header.secure_checkout' }
  },
  {
    path: '/adyen/checkout/:transaction?/:payment_url?',
    name: 'adyen-checkout',
    component: OnlineCheckoutPage,
    meta: { layout: 'simple', key_title: 'header.secure_checkout' }
  },
  {
    path: '/credit-payment/:transaction?/:payment_url?',
    name: 'credit-payment',
    component: CreditCardPayment,
    meta: { layout: 'simple', key_title: 'header.credit_card' }
  },
  {
    path: '/tracker-order/:uuid?',
    name: 'tracker-order',
    component: TrackerOrder,
    meta: { layout: 'simple', key_title: 'header.thanks_for_your_order' }
  },
  {
    path: '/tracking',
    name: 'tracking',
    component: Tracking,
    meta: { layout: 'simple', key_title: 'header.track_your_order' }
  },
  {
    path: '/add-location/:id?',
    name: 'add-location',
    component: AddLocation,
    meta: { layout: 'simple', key_title: 'header.location' }
  },
  {
    path: '/payment-status/:transaction?',
    name: 'payment-status',
    component: PaymentStatus,
    meta: { layout: 'simple', key_title: 'header.thanks_for_your_payment' }
  },
  {
    path: '/careers/:id?',
    name: 'careers',
    component: Careers,
    meta: { layout: 'simple', key_title: 'header.careers' }
  },
  {
    path: '/membership',
    name: 'membership',
    component: Post,
    meta: { layout: 'simple', key_title: 'header.membership' },
    props: { type: 'membership_information', name: 'membership' }
  },
  {
    path: '/indication-based',
    name: 'indication',
    component: Post,
    meta: { layout: 'simple', key_title: 'header.indication' },
    props: {
      type: 'cmn_commercial_transactions',
      name: 'commercial-transactions'
    }
  },
  {
    path: '/nutritional-content',
    name: 'nutritional',
    component: Post,
    meta: { layout: 'simple', key_title: 'footer.nutritional_info' },
    props: { type: '/prd_allergen', name: 'nutritional' }
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: { layout: 'simple', key_title: 'footer.faq' }
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
    meta: { layout: 'simple', key_title: 'footer.terms' }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    meta: { layout: 'simple', key_title: 'footer.privacy' }
  },
  {
    path: '/conditions',
    name: 'conditions',
    component: Conditions,
    meta: { layout: 'simple', key_title: 'footer.conditions' }
  },
  {
    path: '/promotions',
    name: 'promotions',
    component: Promotions,
    meta: { layout: 'simple', key_title: 'footer.promotions' }
  },
  {
    path: '/campaigns-term-and-condition',
    name: 'campaigns-term-and-condition',
    component: Post,
    meta: { layout: 'simple', key_title: 'footer.campaigns_term_and_condition' },
    props: { type: "/campaigns_term_and_condition", name: 'campaigns-term-and-condition' }
  },
  {
    path: '/crm/coupon/:code?',
    name: 'coupon-attach',
    component: CouponAttachment,
    meta: { layout: 'simple', key_title: 'header.coupon_attachment' },
    props: true
  },
  {
    path: '/crs/:mode?/:sub?',
    name: 'crs',
    component: Crs,
    meta: { layout: 'simple', key_title: 'header.crs' }
  },
  {
    path: '/history',
    component: History,
    meta: { layout: 'simple', key_title: 'header.history', auth: true }
  },
  {
    path: '/404',
    name: 'error-404',
    component: { template: '<p>404</p>' }
  },
  {
    path: '/403',
    name: 'error-403',
    component: { template: '<p>403</p>' }
  },
  {
    path: '/combo-detail/:id/:ssid?/:coupon?',
    name: 'combo-detail',
    component: ComboDetail,
    meta: { layout: 'combo', key_title: 'header.combo_detail' }
  },
  {
    path: '/localization/:zip/:opt?',
    name: 'test-localization',
    component: TestLocalization,
    meta: {
      layout: 'simple',
      key_title: 'postal_code',
      sub_title: 'localization.sub_title_result_search',
      key_params: 'zip'
    }
  },
  {
    path: '/result-localization/:zip/:opt?',
    name: 'result-localization',
    component: ResultLocalization,
    meta: {
      layout: 'simple',
      key_title: 'localization.title_result_search',
      key_params: 'zip'
    }
  },
  {
    path: '/confirmation/:regis',
    name: 'confirmation',
    component: Confirmation,
    meta: {
      layout: 'simple',
      key_title: 'registration.thankyou',
      key_params: 'regis'
    }
  },
  {
    path: '/home',
    name: 'home',
    meta: { layout: 'home' },
    component: HomePage
  },
  {
    path: '/topic',
    name: 'topic',
    component: TopicList,
    meta: { layout: 'main', key_title: 'header.topic' }
  },
  {
    path: '/topic/:name/:code?',
    name: 'topic-detail',
    component: TopicDetail,
    meta: { layout: 'main', key_title: 'header.topic' }
  },
  {
    path: '/campaign/:campaignName',
    name: 'campaign',
    component: Campaign,
    meta: { layout: 'Simple', key_title: 'header.campaign' }
  },
  {
    path: '*',
    // redirect: {name: 'home'},
    beforeEnter: (to, from, next) => {
      const login = [
        '/pc/cpn_search',
        '/pc/user_password_reset',
        '/pc/user_detail.2',
        '/pc/user_base_modify.3',
        '/pc/user_password_modify.5',
        '/pc/user_mail_modify.7',
        '/user_mail_modify_confirm.8',
        '/user_mail_modify_mail_sent.9',
        '/pc/user_family_modify.13',
        '/pc/user_pontacard_modify.15'
      ]
      const topics = ['/pc/topics']
      // const faq = ['/pc/general/include/cmn_inquiry.html']
      const member = ['/pc/general/include/usr_member_rules.html']

      const nutrition = [
        '/pc/general/include/prd_allergen.html?link_id=cft_par'
      ]
      const actTrade = [
        '/pc/general/include/cmn_commercial_transactions.html?link_id=cft_cct'
      ]

      const stores = ['/ip/shop_detail', '/pc/shop_detail']

      for (let i in stores) {
        if (lodash.startsWith(to.fullPath, stores[i])) {
          let path = to.fullPath.split('/')
          if (path.length >= 4) {
            return next('/huts/' + path[3])
          }
        }
      }

      for (let i in topics) {
        if (lodash.startsWith(to.fullPath, topics[i])) {
          return next('/topic')
        }
      }

      for (let i in login) {
        if (lodash.startsWith(to.fullPath, login[i])) {
          return next('/login')
        }
      }

      // for (let i in faq) {
      //   if (lodash.startsWith(to.fullPath, faq[i])) {
      //     return next('/faq')
      //   }
      // }

      for (let i in member) {
        if (lodash.startsWith(to.fullPath, member[i])) {
          return next('/membership')
        }
      }

      for (let i in nutrition) {
        if (lodash.startsWith(to.fullPath, nutrition[i])) {
          return next('/nutritional-content')
        }
      }

      for (let i in actTrade) {
        if (lodash.startsWith(to.fullPath, actTrade[i])) {
          return next('/indication-based')
        }
      }
      next('/home')
    }
  }
]
