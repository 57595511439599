var axios = require('axios')
const querystring = require('querystring')

const urlPayment = process.env.VUE_APP_version + '/product-payment-fe/payment'
const urlCardInformation = process.env.VUE_APP_version + '/product-payment-fe/card-information'
const urlBrasPagAccessToken = process.env.VUE_APP_BRASPAG_URL

axios.getPaymentStatus = function (transaction, success, callBackError) {
  this.get(`${urlPayment}/${transaction}`)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.makeCreditPayment = function (data, success, callBackError) {
  this.post(urlPayment + '/softbankjp/credit-payment', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.getCardInformation = function (email, success, callBackError) {
  axios.options.flag = false
  this.get(urlCardInformation + '?email=' + email)
    .then(response => {
      success(response)
    })
}

axios.getBrasPagAccessToken = function (success) {
  this.post(urlBrasPagAccessToken)
    .then(response => {
      success(response)
    })
}

axios.getAdyenPaymentMethods = function(params, success) {
  this.post(`${urlPayment}/adyen/payment-methods`, params).then(response => {
    success(response)
  })
}

axios.makeAdyenPayment = function(data, success, callBackError) {
  this.post(`${urlPayment}/adyen/handle`, data).then(response => {
    success(response.data.data.item)
  }).catch(error => {
    callBackError(error)
  })
}

axios.makeAdyenPaymentDetails = function(data, success, callBackError) {
  this.post(`${urlPayment}/adyen/payment-details`, data).then(response => {
    success(response.data.data.item)
  }).catch(error => {
    callBackError(error)
  })
}

axios.handleAdyenRedirectResult = function(data, success, callBackError) {
  this.post(`${urlPayment}/adyen/redirect-result/handle`, data).then(response => {
    success(response.data.data.item)
  }).catch(error => {
    callBackError(error)
  })
}

export default axios
