var axios = require('axios')
const urlCoupon = process.env.VUE_APP_version + '/product-coupon-fe'

axios.getCouponList = function (param, success, callBackError) {
  this.get(urlCoupon + '/coupons', {params: param})
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400 && typeof callBackError !== 'undefined') {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.getRedeemCouponInfo = function (data, success, callBackError) {
  axios.options.notify = data['isShowNotify'] ? data['isShowNotify'] : false
  this.post(urlCoupon + '/check-existed-coupon/' + data['code'])
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && (error.response.status === 400 || error.response.status == 404) && typeof callBackError !== 'undefined') {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.getRedeemCoupon = function (data, success, callBackError) {
  axios.options.notify = data['isShowNotify'] ? data['isShowNotify'] : false
  this.post(urlCoupon + '/crm-redeem-coupon/' + data['code'])
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && (error.response.status === 400 || error.response.status == 404) &&
        typeof callBackError !== 'undefined') {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

export default axios
