var axios = require('axios')
const urlMenu = process.env.VUE_APP_version2 + '/product-menu-fe/menu'
const urlMenuCombo = process.env.VUE_APP_version2 + '/product-menu-fe/menu/'
const urlCategory = process.env.VUE_APP_version2 + '/product-menu-fe/category'
const urlHalfFirst = process.env.VUE_APP_version2 + '/product-menu-fe/first-layers'
const urlHalfSecond = process.env.VUE_APP_version2 + '/product-menu-fe/second-layers'

axios.getHalf = function (type, data, success, callBackError) {
  var url = urlHalfFirst
  if (type === 2) {
    url = urlHalfSecond
  }

  this.get(url, {params: data})
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400 && typeof callBackError !== 'undefined') {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.getMenuByCategory = function (data, success, callBackError) {  
    this.get(urlMenu, {params: data})
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400 && typeof callBackError !== 'undefined') {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.getMenuCombo = function (data, success, callBackError) {
  this.get(urlMenuCombo, {params: data})
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400 && typeof callBackError !== 'undefined') {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.getMenuDetails = function (uuid, data, success, callBackError) {
  this.get(urlMenu + '/' + uuid, {params: data}).then(response => {
    success(response)
  }).catch(error => {
    if (error.response && error.response.status === 400 && typeof callBackError !== 'undefined') {
      callBackError(error.response.data)
    }
  })
}

axios.getComboDetails = function (uuid, data, success, callBackError) {
  this.get(urlMenuCombo + uuid, {params: data}).then(response => {
    success(response)
  }).catch(error => {
    if (error.response && error.response.status === 400 && typeof callBackError !== 'undefined') {
      callBackError(error.response.data)
    }
  })
}

axios.getListCategory = function (data, success, callBackError) {
  axios.defaults.headers.common['Lang'] = data['lang'] || axios.defaults.headers.common['Lang']

  let url = urlCategory + '?' + data
  this.get(url, data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.getListHalf = function (data, success, callBackError) {
  axios.options.flag = false
  this.get(urlMenu, {params: data})
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

export default axios
