var axios = require('axios')
const url = process.env.VUE_APP_version + '/product-banner-fe'

axios.getBannersByPosition = function (data, success, callBackError) {
  this.get(url + '/banner/positions' + data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400 && typeof callBackError !== 'undefined') {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

export default axios
