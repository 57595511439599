var axios = require('axios')
const urlCart = process.env.VUE_APP_version + '/product-cart-fe'
// const urlCart = 'http://192.168.2.140:8085/v1/product-cart-fe'

axios.addToCart = function (data, success, callBackError) {
  this.post(urlCart + '/cart', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.updateItemCart = function (data, success, callBackError) {
  this.post(urlCart + '/update-cart-item', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.updateCart = function (data, success, callBackError) {
  this.post(urlCart + '/update-item-quantity', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.removeCart = function (data, success, callBackError) {
  this.post(urlCart + '/remove-cart-item', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.removeCartByUuid = function (uuid, success, callBackError) {
  axios.options.flag = false
  axios.options.notify = false

  this.post(urlCart + '/remove-cart', {
    'cart_uuid': uuid
  }).then(response => {
    success(response)
  }).catch(error => {
    if (error.response && error.response.status === 400) {
      if (typeof callBackError !== 'undefined') {
        callBackError(error.response.data)
      }
    }
  })
}
axios.updateCartQuantity = function (data, success, callBackError) {
  axios.options.flag = false
  axios.options.notify = false
  this.post(urlCart + '/update-quantity', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.updateCartData = function (data, success, callBackError) {
  axios.options.flag = false
  axios.options.notify = false
  this.post(urlCart + '/update-cart', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.getCart = function (data, success, callBackError) {
  axios.options.flag = false
  axios.options.notify = false
  this.get(urlCart + '/detail?cart_id=' + data['cart_id'])
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.applyCoupon = function (data, success, callBackError) {
  axios.options.notify = data['isShowNotify'] ? data['isShowNotify'] : false
  this.post(urlCart + '/apply-coupon', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

axios.removeCoupon = function (data, success, callBackError) {
  this.post(urlCart + '/remove-coupon/', data)
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

export default axios
