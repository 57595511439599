var axios = require('axios')
const url = process.env.VUE_APP_version + '/product-campaign-fe/topic'
// const url = 'http://localhost:3001/v1/product-campaign-fe/campaign'

axios.getTopicList = function (data, success, callBackError) {
  this.get(url + '/list', {params: data})
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400 && typeof callBackError !== 'undefined') {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}
axios.getTopicDetail = function (data, success, callBackError) {
  this.get(url + '/detail', {params: data})
    .then(response => {
      success(response)
    }).catch(error => {
      if (error.response && error.response.status === 400 && typeof callBackError !== 'undefined') {
        if (typeof callBackError !== 'undefined') {
          callBackError(error.response.data)
        }
      }
    })
}

export default axios
