<template>
  <div id="app">
    <emergency-banner />
    <component :is="layout">
      <router-view></router-view>
    </component>
    <notifications/>
    <notifications id="notifications" group="notifications"/>
    <!-- <div class="override-loading popup-loading" v-bind:style="{display: loading}"></div> -->
    <v-dialog class="disposition-modal-confirm"/>
    <!-- <div class="override-loading popup-loading" style="display:block"></div> -->
  </div>
</template>

<style lang="scss">
  @import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
  @import './assets/css/plugin/font-awesome.min.css';
  @import './assets/css/main.css';
  @import './assets/css/home.css';
  @import './assets/css/fix.css';
  @import './assets/css/login.css';
  @import './assets/css/fix-all.css';

  .alert {
    z-index: 999;
  }
</style>

<script>
import moment from 'moment'
import _ from 'lodash'
import * as action from './store/actions'
import EmergencyBanner from './components/partials/EmergencyBanner'
import {
  mapGetters,
  mapActions
} from 'vuex'

const main = () => import('./main.js')
const ModalNotify = () => import('./components/popup/ModalNotify')
const OrderASAP = () => import('./components/popup/OrderASAP')

const defaultLayout = 'main'
let isManipulate = false

export default {
  name: 'App',
  components: {
    EmergencyBanner
  },
  data () {
    return {
      loading: false,
      loadingCount: 0,
      isManipulate: false,
      direction: 'ltr',
      idleDetector: null
    }
  },
  watch: {
    '$route': {
      handler: 'routeChanged'
      // immediate: true,
    },
    loadingCount: function (newValue, oldValue) {
      if (this.loadingCount <= 0) {
        this.$store.dispatch('setLoading', false)
      } else {
        this.$store.dispatch('setLoading', true)
      }
    },
    isManipulate: function (newValue, oldValue) { }
  },
  computed: {
    ...mapGetters({
      selectedOrderTime: 'selectedOrderTime',
      categoryList: 'categoryList',
      selectedCategory: 'selectedCategory',
      selectedSubCategory: 'selectedSubCategory',
      lang: 'lang'
    }),
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  },
  created: function () {
    let lang = this.$session.get('lang') || this.$const.DEFAULT_LANGUAGE
    this.setLang(lang)
    this.$cart.init(this)

    registerEvents(this)
    loadCSS(this)
    this.registerEventIdleTime()
    this.setDirection()
  },
  mounted () {
    let vm = this
    this.$root.$on('logout', () => {
      vm.logout()
    })
    this.$root.$on('onShowNotification', (title, message) => {
      title = title || vm.$t('message')
      message = message || vm.$t('message')
      vm.$modal.show(ModalNotify, {
        name: 'modal-notify',
        title: title,
        message: message
      }, {
        class: 'modal-menu',
        transition: 'nice-modal-fade',
        overlayTransition: 'overlay-fade',
        clickToClose: false,
        name: 'modal-notify'
      })
    })
    this.$root.$on('onProcessChangeCategory', () => {
      let menudeal = vm.$store.getters.menuDeal
      if (vm.empty(menudeal)) {
        let orderTime = vm.convertDateTimeFromTimezoneToUTC(vm.$disposition.getByKey(vm, 'timezone', ''),
          vm.$disposition.getByKey(vm, 'collectionTimeValue', ''))
        if (!orderTime) {
          orderTime = moment().utc().format('YYYY-MM-DD HH:mm:ss')
        }

        let data = {
          order_time: orderTime,
          outlet_code: vm.getOutletCode(vm)
        }

        vm.axios.getListCategory(data, function (response) {
          vm.data = response.data.data.items
          vm.$session.set('listCategory', response.data.data.items)
          let _menuDeal = {}
          for (let r in response.data.data.items) {
            _menuDeal = {
              slug: response.data.data.items[r].slug,
              uuid: response.data.data.items[r].uuid,
              lower_categories: response.data.data.items[r].lower_categories
            }
            break
          }
          if (vm.empty(_menuDeal) && response.data.data.items.length > 0) {
            _menuDeal = {
              slug: response.data.data.items[0].slug,
              uuid: response.data.data.items[0].uuid,
              lower_categories: response.data.data.items[0].lower_categories
            }
          }

          vm.$store.commit('setMenuDeal', _menuDeal)
          let slug = _menuDeal.slug
          // if (_menuDeal.lower_categories && _menuDeal.lower_categories.length > 0) {
          //   slug = _menuDeal.lower_categories[0].slug
          // }
          vm.$router.push({'name': 'category', 'params': {'slug': slug}})
        })
      } else {
        vm.$router.push({'name': 'category', 'params': {'slug': menudeal.slug}})
      }
    })
    if (!vm.$browserDetect.isSafari) {
      const bodyElement = document.getElementsByTagName('body')[0]
      bodyElement.classList.add('customized-scrollbar')
    }
  },
  beforeDestroy: function () {
    this.$bus.$off('loading')
  },
  methods: {
    ...mapActions({
      setOrderTime: 'setOrderTime',
      setCategoryList: action.SET_CATEGORY_LIST,
      setSelectedCategory: action.SET_SELECTED_CATEGORY,
      setSelectedCategoryItems: action.SET_SELECTED_CATEGORY,
      setSelectedSubCategory: action.SET_SELECTED_SUB_CATEGORY,
      setLang: action.SET_LANG,
      setUIDirection: action.SET_UI_DIRECTION
    }),
    logout: function () {
      this.$auth.logout({
        data: {
          token: this.$auth.user().token
        },
        success: function (response) {
          if (response.data.status) {
            this.$router.push('/')
          } else {
            this.$notify({
              type: 'Error',
              title: response.data.error.msg
            })
          }
        },
        error: function (data) {
          this.$notify({
            type: 'Error',
            title: this.$t('message_logout_error')
          })
        }
      })
    },
    routeChanged () {
      this.getCategoryList(false)
    },
    showOrderASAP: function (message) {
      let vm = this
      this.$modal.show(OrderASAP, {
        parentlink: vm.getCurrentPath(),
        message: message
      },
      {
        class: ''
      })
    },
    getCategoryList: function (loadAgain = false) {
      let vm = this

      if (!vm.categoryList || !vm.categoryList.length || loadAgain) {
        let orderTime = vm.convertDateTimeFromTimezoneToUTC(vm.$disposition.getByKey(vm, 'timezone', ''),
          vm.$disposition.getByKey(vm, 'collectionTimeValue', ''))
        if (!orderTime) {
          orderTime = moment().utc().format('YYYY-MM-DD HH:mm:ss')
        }

        let request = {
          order_time: orderTime,
          outlet_code: vm.getOutletCode(vm),
          lang: vm.$session.get('lang')
        }

        request = vm.parseQueryString(request)

        vm.axios.getListCategory(request, function (response) {
          const listCategories = _.uniqWith(response.data.data.items, _.isEqual)
          vm.setCategoryList(listCategories)
          vm.$session.set('listCategory', listCategories)

          vm.bindNav()
        })
      } else {
        vm.bindNav()
      }

      /* if (vm.$route.name ===vm.$const.HEADER_MENU.CATEGORY || vm.$route.name == vm.$const.HEADER_MENU.COMBO_DETAIL ||
          vm.$route.name ===vm.$const.HEADER_MENU.TOPIC || vm.$route.name == vm.$const.HEADER_MENU.TOPIC_DETAIL) {

        } */
    },
    bindNav: function () {
      let vm = this
      let selectedCategory = null
      let selectedSubCategory = null
      let slugParent = vm.$route.params.slug
      let slugSub = vm.$route.params.sub

      if (slugParent === vm.$const.MENU_COUPON) {
        let selectedCategory = {}
        selectedCategory.uuid = vm.$const.COUPON_UUID

        vm.setSelectedCategory(selectedCategory)
        vm.$root.$emit('showHideCart', false)
        // vm.$router.push({'name': 'login'})
        return
      } else if (vm.$route.name === vm.$const.HEADER_MENU.CATEGORY) {
        if (!slugParent && vm.categoryList && vm.categoryList.length) {
          if (vm.categoryList[0].lower_categories && vm.categoryList[0].lower_categories.length) {
            vm.setSelectedCategory(vm.categoryList[0])
            vm.setSelectedSubCategory(vm.categoryList[0].lower_categories[0])
            vm.redirectToMenuPage(vm.categoryList[0].slug, vm.categoryList[0].lower_categories[0].slug, null, true)
            return
          } else {
            vm.setSelectedCategory(vm.categoryList[0])
            vm.setSelectedSubCategory(null)
            vm.redirectToMenuPage(vm.categoryList[0].slug, null, null, true)
            return
          }
        }
      }

      if (slugParent === vm.$const.MENU_COUPON) {
        selectedCategory = {}
        selectedCategory.uuid = vm.$const.COUPON_UUID
      } else if (vm.categoryList && vm.categoryList.length) {
        for (let r in vm.categoryList) {
          if (r === 0) {
            let _menuDeal = {
              slug: vm.categoryList[r].slug,
              uuid: vm.categoryList[r].uuid,
              lower_categories: vm.categoryList[r].lower_categories
            }
            vm.$store.commit('setMenuDeal', _menuDeal)
          }

          if (vm.categoryList[r].slug === slugParent) {
            selectedCategory = vm.categoryList[r]
            vm.sub = vm.categoryList[r].lower_categories

            let lowerCategories = vm.categoryList[r].lower_categories

            if (lowerCategories && lowerCategories.length) {
              if (!slugSub) {
                let subCategory = vm.categoryList[r].lower_categories[0]

                vm.setSelectedCategory(selectedCategory)
                vm.setSelectedSubCategory(subCategory)
                vm.redirectToMenuPage(selectedCategory.slug, subCategory.slug, null, true)
                return
              } else {
                for (let s in lowerCategories) {
                  if (lowerCategories[s].slug === slugSub) {
                    selectedSubCategory = lowerCategories[s]
                    break
                  }
                }
              }
            }

            break
          }
        }
      }

      vm.$session.set('categoryUuid', selectedCategory ? selectedCategory.uuid : '')
      vm.setSelectedCategory(selectedCategory)
      vm.setSelectedSubCategory(selectedSubCategory)
    },
    registerEventIdleTime: function () {
      window.addEventListener('mousemove', this.executeIdleTimeOut)
      window.addEventListener('mousedown', this.executeIdleTimeOut)
      window.addEventListener('keypress', this.executeIdleTimeOut)
      window.addEventListener('DOMMouseScroll', this.executeIdleTimeOut)
      window.addEventListener('mousewheel', this.executeIdleTimeOut)
      window.addEventListener('touchmove', this.executeIdleTimeOut)
      window.addEventListener('MSPointerMove', this.executeIdleTimeOut)
      window.addEventListener('click', this.executeIdleTimeOut)
    },
    executeIdleTimeOut: function (e) {
      let vm = this
      if (!vm.isManipulate) {
        vm.isManipulate = true

        checkIdleTime(vm, function (res) {
          if (res) {
            vm.$router.go()
          }

          vm.isManipulate = false
        })
      }
    },
    setDirection () {
      if (this.$i18n.locale === this.$const.LANG_ARABIC) {
        this.setUIDirection('rtl')
        let html = document.getElementsByTagName('body')[0]
        html.dir = 'rtl'

        html = document.getElementsByTagName('html')[0]
        html.dir = 'rtl'
        html.lang = 'ar'

        // this.direction = 'rtl'
      }
    }
  }
}

function registerEvents (vm) {
  vm.$bus.$on(vm.$const.EVENT_BUS.LOADING, ($status) => {
    if ($status) {
      vm.loadingCount++
      if (vm.idleDetector) {
        clearTimeout(vm.idleDetector)
        vm.idleDetector = null
      }
    } else if (vm.loadingCount > 0) {
      vm.loadingCount--
      if (!vm.idleDetector) {
        vm.idleDetector = setTimeout(() => {
          vm.loadingCount = 0
          clearTimeout(vm.idleDetector)
          vm.idleDetector = null
        })
      }
    }
  })

  vm.$bus.$on(vm.$const.EVENT_BUS.LOAD_CATEGORY, (loadAgain = false) => {
    vm.getCategoryList(loadAgain)
  })

  vm.$bus.$on(vm.$const.EVENT_BUS.SHOW_ASAP, () => {
    vm.showOrderASAP(vm.$t('hutfe.error_text_fe_order_time_in_past'))
  })

  vm.$root.$on(vm.$const.EVENT_BUS.REDIRECT_TO_MENU_PAGE, (slug = null, sub = null, uuid = null, isReplace = false) => {
    if (isReplace) {
      vm.$router.replace({
        'name': 'category',
        'params': {
          slug: slug,
          sub: sub || null,
          uuid: uuid || null
        }
      })
    } else {
      vm.$router.push({
        'name': 'category',
        'params': {
          slug: slug,
          sub: sub || null,
          uuid: uuid || null
        }
      })
    }
  })

  vm.$root.$on(vm.$const.EVENT_BUS.REDIRECT_TO_ITEM_PAGE, (slug, subSlug, uuid, isReplace = false) => {
    if (isReplace) {
      vm.$router.replace({
        'name': 'category',
        'params': {
          slug: slug,
          // sub: subSlug,
          uuid: uuid || null
        }
      })
    } else {
      vm.$router.push({
        'name': 'category',
        'params': {
          slug: slug,
          // sub: subSlug,
          uuid: uuid || null
        }
      })
    }
  })
}

function loadCSS (vm) {
  try {
      import('@/assets/css/lang-' + vm.$i18n.locale + '.css')
  } catch (ex) { }
}

function checkIdleTime (vm, callback) {
  let time = localStorage.time
  let now = moment()

  if (time) {
    time = moment(time)

    if (time.isValid && now.diff(time, 'hours', true) >= parseInt(process.env.VUE_APP_TIMEOUT_CART)) {
      vm.$cart.removeAll(vm, function (err, res) {
        if (callback) {
          callback(res)
        }
      })
    } else {
      if (callback) {
        callback(null)
      }
    }
  } else {
    if (callback) {
      callback(null)
    }
  }

  localStorage.time = moment()
}
</script>
