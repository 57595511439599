import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import * as action from './actions'

import {
  mutations,
  getters
} from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: state,
  mutations,
  actions: action.actions,
  getters
})
