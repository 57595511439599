import constants from '../constants'

export default {
  loading: false,
  user_action: '',
  category_uuid: '',
  categoryList: null,
  sub_category_uuid: '',
  coupon_message: '',
  menu_deal: {},
  selectedCategory: null,
  selectedSubCategory: null,
  selectedProduct: null,
  selectedCart: null,
  selectedOrderTime: null,
  selectedOutlet: null,
  selectedHutOutletId: null,
  selectedHutOutlet: null,
  selectedDisposition: null,
  isValidOrderTime: true,
  user: null,
  lang: constants.DEFAULT_LANGUAGE,
  langList: process.env.VUE_APP_LANGUAGES.split(','),
  uiDirection: 'ltr'
}
