import main from '../main.js'

export const SET_ORDER_TIME = 'SET_ORDER_TIME'
export const SET_IS_VALID_ORDER_TIME = 'setIsValidOrderTime'
export const SET_SELECTED_PRODUCT = 'setSelectedProduct'
export const SET_SELECTED_CATEGORY = 'setSelectedCategory'
export const SET_SELECTED_CATEGORY_ITEMS = 'setSelectedCategoryItems'
export const SET_SELECTED_SUB_CATEGORY = 'setSelectedSubCategory'
export const SET_SELECTED_CART = 'setSelectedCart'
export const SET_USER = 'setUser'
export const SET_LANG = 'setLang'
export const SET_USER_ACTION = 'setUserAction'
export const SET_LOADING = 'setLoading'
export const SET_CATEGORY_LIST = 'setCategoryList'
export const SET_SELECTED_OUTLET = 'setSelectedOutlet'
export const SET_HUT_OUTLET_ID = 'setHutOutletId'
export const SET_SELECTED_HUT_OUTLET = 'setSelectedHutOutlet'
export const SET_DISPOSITION = 'setDisposition'
export const SET_UI_DIRECTION = 'setUIDirection'

export const actions = {
  [SET_LANG] ({commit}, payload) {
    commit(SET_LANG, payload)
  },
  setOrderTime ({commit, state}, payload) {
    commit(SET_ORDER_TIME, payload)
  },
  [SET_LOADING] ({commit}, payload) {
    commit(SET_LOADING, payload)
  },
  [SET_USER_ACTION] ({commit}, payload) {
    commit(SET_USER_ACTION, payload)
  },
  [SET_UI_DIRECTION] ({commit, state}, payload) {
    commit(SET_UI_DIRECTION, payload)
  },
  [SET_IS_VALID_ORDER_TIME] ({commit, state}, payload) {
    commit(SET_IS_VALID_ORDER_TIME, payload)
  },
  [SET_CATEGORY_LIST] ({commit, state}, payload) {
    commit(SET_CATEGORY_LIST, payload)
  },
  [SET_SELECTED_PRODUCT] ({commit, state}, payload) {
    commit(SET_SELECTED_PRODUCT, payload)
  },
  [SET_SELECTED_CATEGORY] ({commit, state}, payload) {
    commit(SET_SELECTED_CATEGORY, payload)
  },
  [SET_SELECTED_CATEGORY_ITEMS] ({commit, state}, payload) {
    commit(SET_SELECTED_CATEGORY_ITEMS, payload)
  },
  [SET_SELECTED_SUB_CATEGORY] ({commit, state}, payload) {
    commit(SET_SELECTED_SUB_CATEGORY, payload)
  },
  [SET_SELECTED_CART] ({commit, state}, payload) {
    commit(SET_SELECTED_CART, payload)
  },
  [SET_DISPOSITION] ({commit, state}, payload) {
    commit(SET_DISPOSITION, payload)
  },
  [SET_USER] ({commit, state}, payload) {
    commit(SET_USER, payload)
  },
  [SET_SELECTED_OUTLET] ({commit, state}, payload) {
    if (payload && payload.outletId && payload.orderType) {
      main.setOutletById(payload.outletId, payload.orderType)

      commit(SET_SELECTED_OUTLET, payload)
    }
  },
  [SET_HUT_OUTLET_ID] ({commit, state}, payload) {
    commit(SET_HUT_OUTLET_ID, payload)
  },
  [SET_SELECTED_HUT_OUTLET] ({commit, state}, payload) {
    commit(SET_SELECTED_HUT_OUTLET, payload)
  }
}
