<template>
  <div v-if="show" @click="handleClick" class="emergency-banner"><span class="message"><i class="fa fa-exclamation" style="padding-right: 0.5em; font-size: 22px;" />{{$t('emergency.banner')}}<i class="fa fa-exclamation" style="padding-left: 0.5em; font-size: 22px;" /></span></div>
</template>

<script>
import constants from '../../constants'
export default {
  name: 'EmergencyBanner',
  props: {
  },
  data() {
    return {
      show: constants.SHOW_EMERGENCY_BANNER
    }
  },
  methods: {
    handleClick: function (e) {
      this.show = false
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler: function (value) {
        var _this = this

        this.$nextTick(function () {
          // _this.handleSubstrSentence() # This throws undefined, hence commented out
        })
      }
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
.emergency-banner {
  position: relative;
  background: #d9534f;
  display: inline-block;
  cursor: pointer;
  top: 0;
  right: 0;
  padding: 10px;
  width: 100%;
  text-align: center;
}
.message {
  color: white;
  font-size: 18px;
  margin: auto;
}
</style>
